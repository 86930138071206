import axios from "axios"
import { BASE_URL_EPD, BASE_URL_USER } from "../../config/config"
/* eslint-disable camelcase */

class DashboardService {
  getAllCounts = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_EPD + "count-eco-portal-data", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  getAllUserCounts = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_USER + "users/count-user", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
}

const instance = new DashboardService()

export default instance
