import React, { useState, useRef } from "react"
import { styles } from "../../../styles/Dashboard/data"
import { withMultipleStyles } from "../../../styles/withMultipleStyles"
import { Button } from "@mui/material"
import { showMessage } from "../../../store/Revalu/notificationSlice"
import { useDispatch, useSelector } from "react-redux"
import {
  exportUsers,
  setLoading,
} from "../../../store/RegisteredUser/RegisteredUserSlice"
import TableActionButton from "../Manufacturer/tableActionButton"
import axios from "axios"
import { BASE_HOST_ADMIN_USERS } from "../../../config/config"
import { getToken } from "../../../utils/token"

const ExportUsers = (props) => {
  const dispatch = useDispatch()
  const { classes } = props
  const downloadRef = useRef(null)
  const { loading } = useSelector(
    ({ registerUser }) => registerUser.registeredUserAll,
  )

  const handleExport = async () => {
    try {
      const token = `bearer ${getToken()}`
      dispatch(setLoading(true))
      const res = await axios.get(
        BASE_HOST_ADMIN_USERS + `/users/exportUserData?authorization=` + token,
      )
      const { data, status } = res.data
      if (status === "200") {
        if (data && typeof data === "string" && data !== "error") {
          if (downloadRef && downloadRef.current) {
            downloadRef.current.href = data
            downloadRef.current.download = `Extracted Users ${new Date().toLocaleString()}`
            downloadRef.current.click()
            dispatch(showMessage({ message: "Exported successfully" }))
          }
        } else {
          dispatch(
            showMessage({
              message: "An unknown error occurred!",
              variant: "error",
            }),
          )
        }
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }))
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <>
      <Button
        onClick={handleExport}
        disabled={loading}
        loading={loading}
        style={{ marginLeft: 13 }}
        variant="contained"
        color="primary">
        Export Users
      </Button>

      <a ref={downloadRef} target="_blank" />
    </>
  )
}

export default withMultipleStyles(styles)(ExportUsers)
