import * as React from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { IconButton, Menu, MenuItem, CircularProgress } from "@mui/material"
import { styles } from "../../../../../styles/actionButtons"
import withStyles from "@mui/styles/withStyles"
import { download } from "../../../../../store/LCA/lcaSlice"
import { useDispatch } from "react-redux"
import { showMessage } from "../../../../../store/Revalu/notificationSlice"

const ITEM_HEIGHT = 48

function ActionButtons(props) {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const downloadRef = React.useRef(null)
  const open = Boolean(anchorEl)
  const { classes, rowData, onViewDetails = () => {} } = props

  const { id } = rowData

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const isValidUrl = (val) => {
    return val.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    )
  }

  const downloadFile = (fileUrl, type, isExternal) => {
    try {
      downloadRef.current.href = fileUrl
      if (!isExternal) {
        downloadRef.current.download = `EPD ${type} - ${new Date().toLocaleString()}`
      }
      downloadRef.current.click()
      if (!isExternal) {
        dispatch(showMessage({ message: "File downloaded successfully" }))
      }
    } catch (ex) {
      throw ex
    }
  }
  const downloadJsonFile = (uuid, jsonData, platformName) => {
    const jsonBlob = new Blob([JSON.stringify(jsonData, null, 2)], {
      type: "application/json",
    })
    const url = URL.createObjectURL(jsonBlob)

    const a = document.createElement("a")
    a.href = url
    a.download = uuid + ".json"
    document.body.appendChild(a)
    a.click()

    // Cleanup
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }
  const handleDownload = async (type) => {
    const uuid = rowData?.processInformation?.dataSetInformation?.UUID
    try {
      handleClose()
      setLoading(true)
      const res = await dispatch(
        download({
          id: rowData?.processInformation?.dataSetInformation?.UUID,
          type,
        }),
      )
      if (
        typeof res === "string" &&
        isValidUrl(res) &&
        downloadRef &&
        downloadRef.current
      ) {
        downloadFile(res, type)
      } else if (
        Number(res.status) === 307 &&
        res.data &&
        typeof res.data === "string" &&
        isValidUrl(res.data)
      ) {
        downloadFile(res.data, type, true)
      } else if (type === "JSON") {
        downloadJsonFile(uuid, res, type)
      } else {
        throw new Error("An unknown error occurred!")
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }))
    } finally {
      setLoading(false)
    }
  }

  const handleViewDetails = () => {
    onViewDetails(id)
    handleClose()
  }

  return (
    <div className={classes.ActionButtons}>
      {loading ? (
        <CircularProgress size={20} color="primary" />
      ) : (
        <>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            size="large">
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            style={{
              marginTop: 10,
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            }}>
            <MenuItem onClick={() => handleDownload("PDF")}>
              Download PDF
            </MenuItem>
            <MenuItem onClick={() => handleDownload("JSON")}>
              Download JSON
            </MenuItem>
            <MenuItem onClick={handleViewDetails}>View Details</MenuItem>
          </Menu>
        </>
      )}
      <a
        className="d-none"
        ref={downloadRef}
        target="_blank"
        aria-hidden="true">
        Download
      </a>
    </div>
  )
}
export default withStyles(styles)(ActionButtons)
