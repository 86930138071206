import { createSlice } from "@reduxjs/toolkit"
import AuthService from "./../../services/authService/authService"
import { setUserData } from "./userSlice"
import { Routes } from "../../common/Constants/routes"
import { showMessage } from "../../store/Revalu/notificationSlice"

export const submitLogin =
  ({ email, password }, history) =>
  async (dispatch) => {
    return AuthService.signInWithEmailAndPassword(email, password)
      .then((user) => {
        // dispatch(setUserData(user))
        dispatch(loginSuccess(user))
        localStorage.setItem("userType", user.userType)
        localStorage.setItem("user_id", user.id)
        dispatch(showMessage({ message: "Logged In Successfuly" }))
        history.push(Routes.user.dashboard)
        // history.push(Routes.user.inviteUsers)
      })
      .catch((errors) => {
        dispatch(showMessage({ message: "Error", variant: "error" }))
        return dispatch(loginError(errors))
      })
  }

const initialState = {
  success: false,
  errors: [],
  data: [],
}

const loginSlice = createSlice({
  name: "auth/login",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.success = true
      state.errors = []
      state.data = action.payload
    },
    loginError: (state, action) => {
      state.success = false
      state.errors = action.payload
    },
  },
})

export const { loginSuccess, loginError } = loginSlice.actions

export default loginSlice.reducer
