export const styles = (theme) => ({
  drawerSidebarList: {
    position: "absolute",
    right: "0px",
    background: "transparent",
    top: "135px",
    bottom: "22px",
    zIndex: 99,
    "& span": {
      paddingTop: "8px",
    },
  },
  mblRightDrawer: {
    "& > div:first-child": {
      marginRight: "22px",
      width: "360px",
      background: theme.palette.drawer.background,
      position: "absolute",
      minHeight: 350,
    },
  },
})
