import React, { useEffect, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { TextField, Button, Grid } from "@mui/material"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  AddInvitedUser,
  resetState,
} from "../../../store/InviteUser/inviteUserSlice"
import PasswordField from "../../../common/Fields/PasswordField"
import { showMessage } from "../../../store/Revalu/notificationSlice"
import { Autocomplete } from "@mui/material"
import { list } from "../../../store/Manufactures/manufactureSlice"
const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}))

const InviteUserForm = ({ onClose = () => {} }) => {
  const Users = [
    "ARCHITECT",
    "COMPANY_MEMBER",
    "DEMONSTRATION_USER",
    "MANUFACTURER",
    "SUPPLIER",
  ]
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { addInviteUserLoading, addInvitedUserSuccess, inviteUserData } =
    useSelector(({ inviteUser }) => inviteUser.inviteUserAll)
  const defaultValues = {
    email: "",
    firstName: "",
    lastName: "",
    inviteUserAccountTypes: "",
    referenceId: "",
  }
  const [selectedUserType, setSelectedUserType] = useState(null)
  const [manufacturesList, setManufacturesList] = useState("")

  const { data: manufactureDataList } = useSelector(
    ({ manufactures }) => manufactures.slice,
  )
  const getManufacturesName = () => {
    const names = manufactureDataList?.content.map((m) => m.name)
    if (names.length) {
      const freshNames = names.filter((n) => n)
      setManufacturesList(freshNames)
    }
  }

  const getIdFromManufactureName = (name) => {
    const currentManufacturer = manufactureDataList?.content.filter(
      (f) => f.name === name,
    )
    return currentManufacturer[0]?.id
  }

  useEffect(() => {
    getManufacturesName()
  }, [manufactureDataList])

  useEffect(() => {
    dispatch(list({ pageNo: 0, pageSize: 100000, searchValue: "" }))
  }, [])

  const schema = yup.object().shape({
    manufacturefieldName: yup.string(),
  })

  const {
    control,
    formState,
    handleSubmit,
    setError,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  })
  const { isValid, dirtyFields, errors } = formState
  const handleChangeManufacturerAttribute = (e, name) => {
    setValue("manufacturefieldName", name)
    const itemValue = getIdFromManufactureName(name)
    setValue("referenceId", itemValue)
  }
  const handleSelectUSer = (event, newValue) => {
    setSelectedUserType(newValue)
    setValue("inviteUserAccountTypes", newValue)
  }
  const onResponse = (res) => {
    const { data, status, description } = res
    if (Boolean(data) && Number(status) === 200) {
      if (data?.[0] && data?.[0]["errorMessgae"]) {
        dispatch(
          showMessage({ message: data?.[0]["errorMessgae"], variant: "error" }),
        )
      } else {
        dispatch(showMessage({ message: description }))
      }
      reset()
      onClose()
    } else if (Number(status) === 400) {
      if (
        data &&
        data["database exception"]
          ?.toString()
          ?.toLowerCase()
          ?.indexOf("duplicate") > -1
      ) {
        dispatch(
          showMessage({ message: "User invited already", variant: "error" }),
        )
      }
    }
  }

  const onSubmit = async (model) => {
    const res = await dispatch(AddInvitedUser([model]))
    onResponse(res)
  }

  useEffect(() => {
    if (addInvitedUserSuccess && Boolean(inviteUserData)) {
      reset()
      onClose()
    }
  }, [addInvitedUserSuccess, inviteUserData])

  useEffect(() => {
    return () => {
      dispatch(resetState())
    }
  }, [])

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <Controller
                name="email"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    placeholder="Email"
                    label="Email"
                    inputProps={{ maxLength: 40 }}
                    required
                    id="email-input"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <Controller
                name="firstName"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.firstName}
                    helperText={errors?.firstName?.message}
                    variant="outlined"
                    placeholder="First Name"
                    label="First Name"
                    id="first-name-input"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <Controller
                name="lastName"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.lastName}
                    helperText={errors?.lastName?.message}
                    variant="outlined"
                    placeholder="Last Name"
                    label="Last Name"
                    id="last-name-input"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item>
            <div className={classes.formWrapper}>
              <Autocomplete
                onChange={handleSelectUSer}
                fullWidth
                // disablePortal
                id="fieldName"
                options={Users}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    {...params}
                    label="Account Type"
                  />
                )}
              />
            </div>
          </Grid>
          {selectedUserType === "MANUFACTURER" ? (
            <Grid item>
              <div className={classes.formWrapper}>
                <Autocomplete
                  onChange={handleChangeManufacturerAttribute}
                  fullWidth
                  // disablePortal
                  id="fieldName"
                  options={manufacturesList}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      {...params}
                      label="Attribute"
                    />
                  )}
                />
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!selectedUserType || !isValid || addInviteUserLoading}
          className={classes.submitButton}>
          {addInviteUserLoading ? "Inviting..." : "Invite"}
        </Button>
      </form>
    </div>
  )
}

export default InviteUserForm
