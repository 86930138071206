import { createSlice } from "@reduxjs/toolkit"
import { BuyerService } from "../../services/buyerService"
import { showMessage } from "../Revalu/notificationSlice"
import { Routes } from "../../common/Constants/routes"

export const getAllBuyer = (data) => async (dispatch) => {
  return BuyerService.getAllBuyerData(data)
    .then((products) => {
      dispatch(buyerSuccess(products))
      return true
    })
    .catch((errors) => {
      return true
    })
}

const initialState = {
  success: false,
  errors: [],
  product: "",
  buyerData: [],
  orderItems: [],
}

const buyerSlice = createSlice({
  name: "buyer",
  initialState,
  reducers: {
    productSuccess: (state, action) => {
      state.success = true
      state.product = action.payload
    },
    buyerSuccess: (state, action) => {
      state.success = true
      state.buyerData = action.payload ? [...action.payload] : []
    },
    orderItemSuccess: (state, action) => {
      state.success = true
      state.orderItems = action.payload ? [...action.payload] : []
    },
  },
})

export const { productSuccess, buyerSuccess, orderItemSuccess } =
  buyerSlice.actions

export default buyerSlice.reducer
