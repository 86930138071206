import { createSlice } from "@reduxjs/toolkit"
import { EpdChangelogService } from "../../services/epdChangelogService"

export const fetchEpdChangelog = (id) => async (dispatch) => {
  return EpdChangelogService.fetchEpdChangelog(id)
    .then((res) => {
      return res
    })
    .catch((errors) => {
      return errors
    })
}

const initialState = {
  loading: false,
  epdChangelog: null,
  fetchEpdChangelogLoading: false,
}

const epdChangelogSlice = createSlice({
  name: "qualityValidation/listing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setFetchEpdChangelogLoading: (state, action) => {
      state.fetchEpdChangelogLoading = action.payload
    },
    setEpdChangelog: (state, action) => {
      state.epdChangelog = action.payload
    },
  },
})

export const { setLoading, setEpdChangelog, setFetchEpdChangelogLoading } =
  epdChangelogSlice.actions

export default epdChangelogSlice.reducer
