import { createSlice } from "@reduxjs/toolkit"
import { AttributeService } from "../../services/attributeService"
import { showMessage } from "../Revalu/notificationSlice"
import { Routes } from "../../common/Constants/routes"

export const getAllSource = (data) => async (dispatch) => {
  return AttributeService.getAllSourceData(data)
    .then((res) => {
      dispatch(attributeSourceSuccess(res))
      return true
    })
    .catch((errors) => {
      return true
    })
}

export const getAllSemanticGroup = (data) => async (dispatch) => {
  return AttributeService.getAllSemanticGroupData(data)
    .then((res) => {
      dispatch(attributeSemanticSuccess(res))
      return true
    })
    .catch((errors) => {
      return true
    })
}

export const getAllAttribute = (data) => async (dispatch) => {
  return AttributeService.getAllAttributeData(data)
    .then((res) => {
      dispatch(attributeSuccess(res))
      return true
    })
    .catch((errors) => {
      return true
    })
}

export const submitAddSource = (data, history) => async (dispatch) => {
  return AttributeService.addNewSource(data)
    .then((user) => {
      dispatch(attributeSuccess())
      dispatch(showMessage({ message: "New Source Added successfuly" }))
      history.push(Routes.attribute.source)
      window.location.reload()
      return
    })
    .catch((errors) => {
      return true
    })
}

export const submitAddSemanticGroup = (data, history) => async (dispatch) => {
  return AttributeService.addNewSemanticGroup(data)
    .then((user) => {
      dispatch(attributeSuccess())
      dispatch(showMessage({ message: "New Semantic Group Added successfuly" }))
      history.push(Routes.attribute.semanticGroup)
      window.location.reload()
      return
    })
    .catch((errors) => {
      return true
    })
}

export const submitAddAttribute = (data, history) => async (dispatch) => {
  return AttributeService.addNewAttribute(data)
    .then((user) => {
      dispatch(attributeSuccess())
      dispatch(showMessage({ message: "New Attribute Added successfuly" }))
      history.push(Routes.attribute.attribute)
      window.location.reload()
      return
    })
    .catch((errors) => {
      return true
    })
}

export const updateSource = (data, id, history) => async (dispatch) => {
  return AttributeService.updateDataSource(data, id)
    .then((user) => {
      dispatch(attributeSuccess())
      dispatch(showMessage({ message: "Source Updated successfuly" }))
      history.push(Routes.attribute.source)
      window.location.reload()
      return
    })
    .catch((errors) => {
      return true
    })
}

export const updateSemanticGroup =
  (data, semanticGroup_OldName, history) => async (dispatch) => {
    return AttributeService.updateDataSemanticGroup(data, semanticGroup_OldName)
      .then((user) => {
        dispatch(attributeSuccess())
        dispatch(showMessage({ message: "Semantic Group Updated successfuly" }))
        history.push(Routes.attribute.semanticGroup)
        window.location.reload()
        return
      })
      .catch((errors) => {
        return true
      })
  }

export const updateAttribute = (data, history) => async (dispatch) => {
  return AttributeService.updateDataAttribute(data)
    .then((user) => {
      dispatch(attributeSuccess())
      dispatch(showMessage({ message: "Attribute Updated Successfuly" }))
      history.push(Routes.attribute.attribute)
      window.location.reload()
      return
    })
    .catch((errors) => {
      return true
    })
}

export const updateSelectedData_Source = (data) => async (dispatch) => {
  return AttributeService.updateAttributeSelectedData(data)
    .then((res) => {
      dispatch(attributeSelectedSuccess_Souce(res))
      return true
    })
    .catch((errors) => {
      dispatch(attributeSelectedSuccess_Souce(errors))
      return false
    })
}

export const updateSelectedData_Semantic = (data) => async (dispatch) => {
  return AttributeService.updateAttributeSelectedData(data)
    .then((res) => {
      dispatch(attributeSelectedSuccess_Semantic(res))
      return true
    })
    .catch((errors) => {
      dispatch(attributeSelectedSuccess_Semantic(errors))
      return false
    })
}

export const updateSelectedData_Att = (data) => async (dispatch) => {
  return AttributeService.updateAttributeSelectedData(data)
    .then((res) => {
      dispatch(attributeSelectedSuccess_Att(res))
      return true
    })
    .catch((errors) => {
      dispatch(attributeSelectedSuccess_Att(errors))
      return false
    })
}

export const deleteSource = (data) => async (dispatch) => {
  return AttributeService.deleteSourceData(data)
    .then((res) => {
      dispatch(showMessage({ message: "Source Deleted successfuly" }))
      window.location.reload()
      return
    })
    .catch((errors) => {
      return true
    })
}

export const deleteSemanticGroup = (data) => async (dispatch) => {
  return AttributeService.deleteSemanticGroupData(data)
    .then((res) => {
      dispatch(showMessage({ message: "Semantic Group Deleted successfuly" }))
      window.location.reload()
      return
    })
    .catch((errors) => {
      return true
    })
}

export const deleteAttribute = (name) => async (dispatch) => {
  return AttributeService.deleteAttributeData(name)
    .then((res) => {
      dispatch(showMessage({ message: "Attribute Deleted successfuly" }))
      window.location.reload()
      return
    })
    .catch((errors) => {
      return true
    })
}

export const uploadCsv = (formData) => async (dispatch) => {
  return AttributeService.csvUpload(formData)
    .then((res) => {
      dispatch(showMessage({ message: res }))

      return res
    })
    .catch((errors) => {
      dispatch(
        showMessage({
          message: errors[0]?.message ? errors[0]?.message : "Error, Try Again",
          variant: "error",
        }),
      )
      return errors
    })
}

const initialState = {
  success: false,
  errors: [],
  product: "",
  attributeData: [],
  sourceUpdateData: [],
  semanticUpdateData: [],
  attUpdateData: [],
  attributeSourceData: [],
  attributeSemanticData: [],
}

const attributeSlice = createSlice({
  name: "attribute/listing",
  initialState,
  reducers: {
    productSuccess: (state, action) => {
      state.success = true
      state.product = action.payload
    },
    attributeSuccess: (state, action) => {
      state.success = true
      state.attributeData = action.payload ? [...action.payload] : []
    },
    attributeSourceSuccess: (state, action) => {
      state.success = true
      state.attributeSourceData = action.payload ? [...action.payload] : []
    },
    attributeSemanticSuccess: (state, action) => {
      state.success = true
      state.attributeSemanticData = action.payload ? [...action.payload] : []
    },
    attributeSelectedSuccess_Souce: (state, action) => {
      state.success = true
      state.sourceUpdateData = action.payload ? [action.payload] : []
    },
    attributeSelectedSuccess_Semantic: (state, action) => {
      state.success = true
      state.semanticUpdateData = action.payload ? [action.payload] : []
    },
    attributeSelectedSuccess_Att: (state, action) => {
      state.success = true
      state.attUpdateData = action.payload ? [action.payload] : []
    },
  },
})

export const {
  productSuccess,
  attributeSuccess,
  orderItemSuccess,
  attributeSourceSuccess,
  attributeSelectedSuccess_Souce,
  attributeSelectedSuccess_Semantic,
  attributeSelectedSuccess_Att,
  attributeSemanticSuccess,
} = attributeSlice.actions

export default attributeSlice.reducer
