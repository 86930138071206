import React, { useEffect } from "react"
import { styles } from "../../../styles/Dashboard/landing"
import withStyles from "@mui/styles/withStyles"
import Header from "../Common/header"
import DashboardIcon from "@mui/icons-material/Dashboard"
import Summary from "../Common/summary"
import { useSelector, useDispatch } from "react-redux"
import {
  getAllCounts,
  getAllUserCounts,
} from "../../../store/Dashboard/dashboardSlice"

const DashboardLanding = (props) => {
  const { classes } = props
  const dispatch = useDispatch()

  const viewAllEpdCounts = useSelector(
    ({ dashboard }) => dashboard.dashboardAll.dashboardData,
  )
  const viewAllUserCounts = useSelector(
    ({ dashboard }) => dashboard.dashboardAll.dashboardUserData,
  )

  useEffect(() => {
    const fetchEpdData = async () => {
      let data = {}
      const isValid = await dispatch(getAllCounts(data))
      return isValid
    }
    const fetchUserData = async () => {
      let data = {}
      const isValid = await dispatch(getAllUserCounts(data))
      return isValid
    }

    fetchEpdData()
    fetchUserData()
  }, [dispatch])

  return (
    <div className={classes.mainWrapper}>
      <div style={{ display: "flex" }}>
        <div>
          <Header icon={<DashboardIcon />} title="Dashboard" />
          <Summary
            viewAllEpdCounts={viewAllEpdCounts}
            totalUsers={viewAllUserCounts}
          />
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(DashboardLanding)
