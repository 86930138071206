import React, { useState, useEffect } from "react"
import { styles } from "../../../styles/Dashboard/data"
import { styles as dataTableStyles } from "../../../styles/dataTable"

// redux
import { useSelector, useDispatch } from "react-redux"
import {
  list,
  updateList,
  searchData,
  setSelected,
  resetSelected,
} from "../../../store/Contact/contactSlice"

// material ui
import {
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import MUIDataTable from "mui-datatables"

// imports
import { withMultipleStyles } from "../../../styles/withMultipleStyles"
import ActionButtons from "./actionButtons"
import { showMessage } from "../../../store/Revalu/notificationSlice"
import Search from "./Search"
import ViewDetails from "./viewDetails"
import TableActionButton from "./tableActionButton"
import CreateContactForm from "./createContact"

const AllContacts = (props) => {
  const [pageNo, setPageNo] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const dispatch = useDispatch()

  const {
    data,
    loading,
    exportProcessStatus,
    exportProcessLoading,
    searchValue,
    selected: currentRecord,
  } = useSelector(({ contacts }) => contacts.slice)

  const { totalElements } = data
  const [selectedId, setSelectedId] = useState(null)
  const [selectedIdToView, setSelectedIdToView] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [update, setUpdate] = useState(false)
  const refreshList = () => setUpdate((prev) => setUpdate(!prev))

  const handleSelect = (id) => {
    dispatch(setSelected({ id }))
    setSelectedId(id)
    setShowAddModal(true)
  }

  const handleViewDetails = (id) => {
    setSelectedIdToView(id)
  }

  const handleCloseDetails = () => {
    setSelectedIdToView(null)
  }

  const hideModal = () => {
    setShowModal(false)
    setShowAddModal(false)
    dispatch(resetSelected())
  }

  const updateTable = () => {
    dispatch(list({ pageNo, pageSize, searchValue }))
  }

  useEffect(() => {
    searchValue ? setPageNo(0) : pageNo
    updateTable()
  }, [pageNo, pageSize, searchValue, update])

  useEffect(() => {
    searchValue ? setPageNo(0) : pageNo
    dispatch(updateList({ pageNo, pageSize, searchValue }))
  }, [])

  const headers = [
    { name: "Contact Name", options: { sort: false } },
    { name: "Email", options: { sort: false } },
    { name: "Telephone No", options: { sort: false } },
    { name: "Street", options: { sort: false } },
    { name: "City", options: { sort: false } },
    { name: "Country", options: { sort: false } },
    { name: "Zip Code", options: { sort: false } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <ActionButtons
              onEdit={handleSelect}
              onViewDetails={handleViewDetails}
              rowData={value}
            />
          )
        },
      },
    },
  ]
  const getParsedData = () => {
    return data?.content?.map((m) => [
      m.contactName,
      m?.email,
      m?.telephoneNo,
      m?.street,
      m?.city,
      m?.country,
      m?.zipCode,
      {
        id: m?.id,
      },
    ])
  }

  const { classes } = props

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    search: false,
    rowsPerPageOptions: [10, 20, 50],
    onSearchClose: () => {
      dispatch(searchData({ search: "" }))
    },
    customSearchRender: (hideSearch) => {
      return (
        <Search
          classes={classes}
          hideSearch={hideSearch}
          onSearch={searchData}
          placeholder="Search"
        />
      )
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state
      switch (action) {
        case "changePage":
          setPageNo(page)
          break
        case "changeRowsPerPage":
          setPageSize(rowsPerPage)
          break
      }
    },
  }

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.headerContent}>
        <div className={classes.headerOptions}>
          <Box display="flex" width="100%" gap="10px" flexWrap="wrap"></Box>
          <Box
            display="inline-flex"
            justifyContent="flex-end"
            gap="10px"
            flexWrap="wrap">
            <TableActionButton
              onClick={() => {
                setShowAddModal(true)
              }}
              title="Add Contact"
            />
          </Box>
        </div>
      </div>

      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}

      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <MUIDataTable
            title="Contacts for Manufacturers"
            columns={headers}
            data={getParsedData()}
            options={{ ...options, count: totalElements }}
            onPageChange={() => {}}
          />
        </div>
      </div>
      {showAddModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showAddModal}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>
                {Boolean(currentRecord) ? "Update" : "Create"} Contact for
                Manufacturer
              </Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <CreateContactForm
            currentRecord={currentRecord}
            onClose={hideModal}
            refreshList={refreshList}
          />
        </Dialog>
      )}

      {Boolean(selectedIdToView) && (
        <Dialog
          open={Boolean(selectedIdToView)}
          maxWidth="lg"
          fullWidth
          scroll="body">
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>Contact Details</Box>
              <Box textAlign="right">
                <IconButton onClick={handleCloseDetails} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <ViewDetails onClose={handleCloseDetails} id={selectedIdToView} />
        </Dialog>
      )}
    </div>
  )
}

export default withMultipleStyles(styles, dataTableStyles)(AllContacts)
