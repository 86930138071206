import { createSlice } from "@reduxjs/toolkit"
import { OrderService } from "../../services/orderService"
import { showMessage } from "../Revalu/notificationSlice"
import { Routes } from "../../common/Constants/routes"

export const getAllOrders = (data) => async (dispatch) => {
  return OrderService.getAllOrderData(data)
    .then((res) => {
      dispatch(orderSuccess(res))
      return true
    })
    .catch((errors) => {
      return true
    })
}

const initialState = {
  success: false,
  errors: [],
  product: "",
  orderData: [],
  orderItems: [],
}

const orderSlice = createSlice({
  name: "order/listing",
  initialState,
  reducers: {
    productSuccess: (state, action) => {
      state.success = true
      state.product = action.payload
    },
    orderSuccess: (state, action) => {
      state.success = true
      state.orderData = action.payload ? [...action.payload] : []
    },
    orderItemSuccess: (state, action) => {
      state.success = true
      state.orderItems = action.payload ? [...action.payload] : []
    },
  },
})

export const { productSuccess, orderSuccess, orderItemSuccess } =
  orderSlice.actions

export default orderSlice.reducer
