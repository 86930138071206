export const styles = (theme) => ({
  notification: {
    background: theme.palette.primary.lighter,
    color: theme.palette.primary.main,
    padding: "7px 10px",
    borderRadius: "4px",
    maxWidth: "400px",
    marginRight: "auto",
  },
  pageWrapper: {
    padding: 22,
    position: "relative",
    marginBottom: 32,
    "& .title": {
      color: theme.palette.primary.heading,
      fontSize: 20,
    },
    maxWidth: "100%",
  },
  loader: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.3)",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px 20px",
    zIndex: 999,
    "& >div": {
      zIndex: 1000,
    },
  },
  hidden: {
    display: "none",
  },

  actionBtns: {
    display: "flex",
    gap: "10px",
  },

  headerContent: {
    display: "flex",
    background: theme.palette.headers.background,
    alignItems: "center",
    padding: "11px 17px",
    "& button": {
      minWidth: 100,
    },
    "& .lightButton": {
      background: theme.palette.headers.button.background,
      color: theme.palette.headers.button.color,
    },
    "& .activeButton": {
      background: theme.palette.headers.activeButton.background,
      color: theme.palette.headers.activeButton.color,
    },
  },
  headerOptions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "20px",
    "& svg": {
      color: theme.palette.headers.icons,
      fontSize: 17,
      cursor: "pointer",
    },
  },
  customMaterialDataTable: {
    "& .MuiTablePagination-root": {
      display: "none",
    },
  },
  buttonGroup: {
    display: "flex",
    gap: "8px",
    "& input": {
      display: "none",
    },
  },
})
