import { createSlice } from "@reduxjs/toolkit"
import AuthService from "./../../services/authService/authService"
import { setUserData } from "./userSlice"
import { showMessage } from "../../store/Revalu/notificationSlice"
import { Routes } from "../../common/Constants/routes"

export const submitRegister =
  ({ displayName, password, email }, history) =>
  async (dispatch) => {
    return AuthService.createUser({
      displayName,
      password,
      email,
    })
      .then((user) => {
        dispatch(setUserData(user))
        dispatch(registerSuccess())
        localStorage.setItem("email_id", user?.email)
        history.push(Routes.login)
        return
      })
      .catch((errors) => {
        return dispatch(registerError(errors))
      })
  }

export const uploadLogo = (logo) => async (dispatch) => {
  return SellerService.uploadLogo(logo)
    .then((image) => {
      dispatch(profileLogoSuccess(image))
      dispatch(
        showMessage({
          message: "Logo Uploaded Successfully",
        }),
      )
      return true
    })
    .catch((errors) => {
      dispatch(
        showMessage({
          message: "Error",
          variant: "error",
        }),
      )
      return false
    })
}

const initialState = {
  success: false,
  errors: [],
  profileImage: "",
}

const registerSlice = createSlice({
  name: "seller/register",
  initialState,
  reducers: {
    registerSuccess: (state, action) => {
      state.success = true
      state.errors = []
    },
    registerError: (state, action) => {
      state.success = false
      state.errors = action.payload ? action.payload : []
    },
    profileLogoSuccess: (state, action) => {
      state.profileImage = action.payload
    },
  },
})

export const { registerSuccess, registerError, profileLogoSuccess } =
  registerSlice.actions

export default registerSlice.reducer
