import history from "../utils/history"

export const getSession = (type = "user") => {
  return window.localStorage.getItem("jwt_access_token")
}

export const clearSession = () => {
  localStorage.clear()
}

export const logout = () => {
  clearSession()
  history.push("/login")
}
