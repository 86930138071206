import * as React from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import {
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Button,
} from "@mui/material"
import { styles } from "../../../styles/Dashboard/data"
import withStyles from "@mui/styles/withStyles"
import {
  changeBlockedStatus,
  deleteById,
  list,
  getManufactureContact,
  deleteManufactureContact,
  changeFeaturedManufacturerStatus,
} from "../../../store/Manufactures/manufactureSlice"

import { useDispatch } from "react-redux"
import { showMessage } from "../../../store/Revalu/notificationSlice"
import AlertDialog from "../../../common/AlertDialog/AlertDialog"
import { setConfirmation } from "../../../store/AlertDialog/alertDialogSlice"

const ITEM_HEIGHT = 48
const STATUS = {
  WARNING: "Warning",
  SUCCESS: "Success",
}

function ActionButtons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [alert, setAlert] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const open = Boolean(anchorEl)
  const {
    classes,
    rowData,
    onEdit = () => {},
    onViewDetails = () => {},
    onViewWarnings = () => {},
    onQualityCheck,
    onQualityStatus = () => {},
    onCategoryHierarchy = () => {},
    onContactLinking = () => {},
    onSupplierLinking = () => {},
    onViewSuppliers = () => {},
  } = props
  const { id, blocked, status, featuredManufacturer } = rowData
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleEdit = () => {
    onEdit(id)
    handleClose()
  }
  const handleViewDetails = () => {
    onViewDetails(id)
    handleClose()
  }
  const handleViewWarnings = () => {
    onViewWarnings(id)
    handleClose()
  }

  const handlequalitycheck = () => {
    onQualityCheck(id)
  }

  const handlequalitystatus = () => {
    onQualityStatus(id, status)
    handleClose()
  }

  const handleDelete = () => {
    setAlert(true)
    handleClose()
  }
  const handleCancel = () => {
    dispatch(setConfirmation(false))
    setAlert(false)
  }

  const handleContactLinking = () => {
    onContactLinking(id)
  }

  const handleSupplierLinking = () => {
    onSupplierLinking(id)
  }

  const handleConfirm = async () => {
    try {
      setLoading(true)
      setAlert(false)
      const res = await dispatch(deleteById(id))
      if (res?.deletedCount) {
        dispatch(showMessage({ message: "Record deleted" }))
        await dispatch(list())
      } else {
        dispatch(
          showMessage({
            message: "An unknown error occurred",
            variant: "error",
          }),
        )
      }
    } catch (ex) {
      dispatch(
        showMessage({
          message: ex.message ? ex.messge : ex,
          variant: "error",
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  const handleViewSuppliers = () => {
    onViewSuppliers(id)
  }

  const handleDeleteManufactureContact = async () => {
    handleClose()
    try {
      setLoading(true)
      const res = await dispatch(getManufactureContact(id))

      if (res.length) {
        const lastObject = res[res.length - 1]

        if (lastObject) {
          const response = await dispatch(
            deleteManufactureContact({
              contactManufacturerId: lastObject?.id,
              manufacturerId: id,
            }),
          )
          if (response) {
            dispatch(
              showMessage({
                message: `Delink Successfully!`,
              }),
            )
          }
        } else {
          dispatch(
            showMessage({
              message: `This manufacturer have no contact!`,
              variant: "error",
            }),
          )
        }
      } else {
        dispatch(
          showMessage({
            message: `This manufacturer has no contact!`,
            variant: "error",
          }),
        )
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }))
    } finally {
      setLoading(false)
    }
  }

  const handleChangeStatus = async () => {
    try {
      handleClose()
      setLoading(true)
      const res = await dispatch(changeBlockedStatus({ id, blocked: !blocked }))
      if (res?.modifiedCount) {
        dispatch(
          showMessage({
            message: `${!blocked ? "Blocked" : "Unblocked"} successfully`,
          }),
        )
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }))
    } finally {
      setLoading(false)
    }
  }

  const handleToggleFeaturedManufacturer = async () => {
    try {
      handleClose()
      setLoading(true)
      const res = await dispatch(
        changeFeaturedManufacturerStatus({
          id,
          featuredManufacturer: !featuredManufacturer,
        }),
      )
      if (res?.modifiedCount) {
        dispatch(
          showMessage({
            message: `${
              !featuredManufacturer ? "Unpublished" : "Published"
            } successfully`,
          }),
        )
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }))
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <CircularProgress size={20} color="primary" />
  }

  return (
    <div className={classes.ActionButtons}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          marginTop: 10,
          maxHeight: ITEM_HEIGHT * 7.0,
          width: "20ch",
        }}>
        <MenuItem onClick={handleChangeStatus}>
          {blocked ? "Unblock" : "Block"}
        </MenuItem>
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleViewDetails}>View Details</MenuItem>
        <MenuItem onClick={handleToggleFeaturedManufacturer}>
          {featuredManufacturer ? "Unpublish" : "Publish"}
        </MenuItem>
        <MenuItem onClick={handleContactLinking}>Contact Linking</MenuItem>
        <MenuItem onClick={handleSupplierLinking}>Supplier Linking</MenuItem>
        <MenuItem onClick={handleViewSuppliers}>View Suppliers</MenuItem>
        <MenuItem onClick={handleDeleteManufactureContact}>
          Delink Contact
        </MenuItem>
        {status &&
          status.toLowerCase() !== STATUS.SUCCESS.toLocaleLowerCase() && (
            <MenuItem onClick={handlequalitystatus}>
              Update Data Quality Status
            </MenuItem>
          )}
      </Menu>

      {alert ? (
        <AlertDialog
          title="Delete EPD"
          message="Are you sure want to delete this EPD?"
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      ) : (
        ""
      )}
    </div>
  )
}
export default withStyles(styles)(ActionButtons)
