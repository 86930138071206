import { createSlice } from "@reduxjs/toolkit"
import { ObjectTypeService } from "../../services/objectTypeService"

export const list = (data) => async (dispatch) => {
  dispatch(setLoading(true))
  return ObjectTypeService.list(data)
    .then((res) => {
      dispatch(setData(res))
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false))
      return true
    })
}

export const create = (data) => async (dispatch) => {
  dispatch(setRecordLoading(true))
  return ObjectTypeService.create(data)
    .then(async (res) => {
      setLoading(true)
      ObjectTypeService.list(data)
        .then((res) => {
          dispatch(setData(res))
        })
        .finally(() => {
          setLoading(false)
        })
      return res
    })
    .catch((errors) => {
      return errors
    })
    .finally(() => {
      dispatch(setRecordLoading(false))
    })
}
export const update = (data, id) => async (dispatch) => {
  dispatch(setRecordLoading(true))
  return ObjectTypeService.update(data, id)
    .then((res) => {
      const { id } = res
      if (Boolean(id)) {
        dispatch(modifyRecordInState(res))
      }
      return res
    })
    .catch((errors) => {
      return errors
    })
    .finally(() => {
      dispatch(setRecordLoading(false))
    })
}
export const deleteByName = (name) => async (dispatch) => {
  dispatch(setLoading(true))
  return ObjectTypeService.deleteByName(name)
    .then(async (res) => {
      dispatch(removeRecordFromStateByName(name))
    })
    .catch((errors) => {})
    .finally(() => {
      dispatch(setLoading(false))
      return true
    })
}

const initialState = {
  data: [],
  loading: false,
  recordLoading: false,
  selected: null,
}

const objectTypeSlice = createSlice({
  name: "objectType/listing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setRecordLoading: (state, action) => {
      state.recordLoading = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload || []
    },
    modifyRecordInState: (state, action) => {
      const { id } = action.payload
      const index = state.data.findIndex(
        (f) => f.id.toString() === id.toString(),
      )
      if (index > -1) {
        state.data[index] = action.payload
      }
    },
    setSelected: (state, action) => {
      const { id } = action.payload
      const index = state.data.findIndex(
        (f) => f.id.toString() === id.toString(),
      )
      if (index > -1) {
        state.selected = state.data[index]
      }
    },
    resetSelected: (state, action) => {
      state.selected = null
    },
    removeRecordFromStateByName: (state, action) => {
      const name = action.payload
      const indexToRemove = state.data.findIndex((f) => f.name === name)
      if (indexToRemove > -1) {
        state.data.splice(indexToRemove, 1)
      }
    },
    resetState: (state) => {
      state.recordLoading = false
    },
  },
})

export const {
  setLoading,
  setRecordLoading,
  setData,
  modifyRecordInState,
  removeRecordFromStateByName,
  resetState,
  setSelected,
  resetSelected,
} = objectTypeSlice.actions

export default objectTypeSlice.reducer
