import axios from "axios"
import { BASE_HOST_ADMIN_USERS, BASE_URL_EPD } from "../../config/config"
import axiosRequest from "../axiosReq"
/* eslint-disable camelcase */

class CustomMaterialService {
  addUUIDInCustomMaterial = (data) => {
    const token = `bearer ${localStorage.getItem("jwt_access_token")}`
    const { id, uuid } = data
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_HOST_ADMIN_USERS +
            "/custom-material/addUUIDInCustomMaterial?authorization=" +
            token +
            "&id=" +
            id +
            "&uuid=" +
            uuid,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  list = (data) => {
    const token = `bearer ${localStorage.getItem("jwt_access_token")}`
    const { pageNo = 1, pageSize = 10, searchValue = "", sortBy = {} } = data
    return new Promise((resolve, reject) => {
      axios
        .post(
          BASE_HOST_ADMIN_USERS +
            "/custom-material/getPaginatedCustomMaterial?authorization=" +
            token +
            "&pageNo=" +
            pageNo +
            "&pageSize=" +
            pageSize +
            "&identifier=" +
            searchValue,
          sortBy,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  getEmail = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_HOST_ADMIN_USERS + "/users/getUserById?id=" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  getById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_HOST_ADMIN_USERS + "/users/getUserById?id=" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  setCustomMaterialStatus = (id, status) => {
    const token = `bearer ${localStorage.getItem("jwt_access_token")}`
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_HOST_ADMIN_USERS +
            `/custom-material/setCustomMaterialStatus?authorization=${token}&customMaterialStatus=${status}&id=${id}`,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  addMaterial = () => {
    const req = axios.put(BASE_URL_EPD + "extracted/addCustomUUID")
    return axiosRequest(req)
  }

  deleteMaterial = (id) => {
    const token = localStorage.getItem("jwt_access_token")
    return new Promise((resolve, reject) => {
      axios
        .delete(
          BASE_HOST_ADMIN_USERS +
            "/custom-material/delete?authorization=" +
            `Bearer ${token}` +
            "&id=" +
            id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
}

const instance = new CustomMaterialService()

export default instance
