import React from "react"
import { Button, CircularProgress } from "@mui/material"

const TableActionButton = ({
  onClick = () => {},
  disabled = false,
  loading = false,
  title,
}) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      disabled={disabled}
      color="primary">
      {loading ? (
        <>
          <CircularProgress size={20} color="primary" />
          &nbsp; Please wait
        </>
      ) : (
        title
      )}
    </Button>
  )
}
export default TableActionButton
