import React, { useState, useEffect } from "react"
import { styles } from "../../../styles/Dashboard/data"
import withStyles from "@mui/styles/withStyles"
import DataTable_New from "../../../common/DataTable/dataTable_new"
import {
  getAllAdminUser,
  deleteAdminUser,
  resetSelected,
} from "../../../store/AdminUser/adminUserSlice"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@mui/material"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import CreateAdminUserForm from "../Manufacturer/createAdminUserForm"
import { Dialog, DialogTitle, Box } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import { Delete, Edit } from "@mui/icons-material"
import { CircularProgress } from "@mui/material"
import { showMessage } from "../../../store/Revalu/notificationSlice"
import ActionButtons from "./actionButtonsInvitedUsers"
import { getAllInvitedUsers } from "../../../store/InviteUser/inviteUserSlice"
import InviteUserForm from "./InviteUserForm"
import InviteUsersTable from "../../../common/DataTable/InviteUsersTable"
import { INVITE_USER_STATUS } from "../../../store/App/constants"
const headings = ["ID", "Name", "Email", "Status", "Time Stamp", "Actions"]

const AllInvitedUsers = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [, setSelectedRow] = useState(null)
  const { classes } = props

  const dispatch = useDispatch()

  const viewAllInvitedUsers = useSelector(
    ({ inviteUser }) => inviteUser.inviteUserAll.data,
  )

  const { loading, selected: currentRecord } = useSelector(
    ({ inviteUser }) => inviteUser.inviteUserAll,
  )

  const handleRowClick = (data) => {
    setSelectedRow(data)
  }
  const toggleModal = () => {
    setShowModal((prev) => !prev)
  }
  const hideModal = () => {
    setShowModal(false)
    dispatch(resetSelected())
  }

  useEffect(() => {
    const fetchData = async () => {
      let data = {}
      await dispatch(getAllInvitedUsers(data))
    }
    fetchData()
  }, [dispatch])

  let rowsData = viewAllInvitedUsers.map((item) => {
    return [
      item.id,
      item.firstName + " " + item.lastName,
      item.email,
      INVITE_USER_STATUS[item.currentUserStatus] || "",
      item.lastModifiedDate || "",
    ]
  })

  return (
    <>
      <div className={classes.pageWrapper}>
        <h1 className="title">Invited Users</h1>

        {loading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : null}
        <div className={classes.headerContent}>
          <div className={classes.headerOptions}>
            <Button
              style={{ marginLeft: 13 }}
              variant="contained"
              color="primary"
              onClick={toggleModal}
              startIcon={<AddCircleIcon style={{ color: "#c4cbda" }} />}>
              Invite new
            </Button>
          </div>
        </div>
        <InviteUsersTable
          hideColumnsIndexes={[0]}
          headings={headings}
          rowsData={rowsData}
          allowActionButtons
          onRowClick={handleRowClick}
          ActionButtons={ActionButtons}
        />
      </div>
      {showModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showModal}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>Invite User</Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <InviteUserForm onClose={hideModal} />
        </Dialog>
      )}
    </>
  )
}

export default withStyles(styles)(AllInvitedUsers)
