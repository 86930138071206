import React, { useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { v4 as uuidv4, v5 as uuidv5 } from "uuid"
import {
  TextField,
  Button,
  Grid,
  Switch,
  FormControlLabel,
} from "@mui/material"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"

import { useSelector, useDispatch } from "react-redux"

import { showMessage } from "../../../store/Revalu/notificationSlice"
import { useState } from "react"
import { Autocomplete } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { setCustomMaterialStatus } from "../../../store/CustomMaterial/customMaterial"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}))

const EditCustomMaterial = ({ id, onClose = () => {} }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const defaultValues = {
    status: "",
    id: id,
  }

  const addNewSchema = yup.object().shape({
    id: yup.string().required("Required"),
    status: yup.string().required("Required"),
  })

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(addNewSchema),
  })
  const { isValid, dirtyFields, errors } = formState

  const STATUSES = ["OPEN", "INPROGRESS", "DONE", "DISMISSED"]

  const onResponse = (res) => {
    if (Boolean(res)) {
      dispatch(
        showMessage({
          message: `The status has been changed`,
        }),
      )
      reset()
      onClose()
    } else if (Number(status) === 400) {
      if (
        data &&
        data["database exception"]
          ?.toString()
          ?.toLowerCase()
          ?.indexOf("duplicate") > -1
      ) {
        dispatch(showMessage({ message: "already exists", variant: "error" }))
      }
    }
  }

  const onSubmit = async (model) => {
    setLoading(true)
    try {
      const res = await dispatch(setCustomMaterialStatus(model))
      onResponse(res)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleStatus = (e, newValue) => {
    setValue("status", newValue)
  }

  return (
    <>
      <div className={classes.wrapper}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={7} md={7}>
              <div className={classes.formWrapper}>
                <label className={classes.label}>Type</label>
                <Autocomplete
                  onChange={handleStatus}
                  fullWidth
                  // disablePortal
                  id="fieldName"
                  options={STATUSES}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      id="status-input"
                      fullWidth
                      required
                      {...params}
                      label="Material Status"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={1} md={1}></Grid>
            <Grid item xs={4} md={4}>
              <div className={classes.formWrapper}>
                <label className={classes.label}>Selected Type</label>
                <Controller
                  name="status"
                  control={control}
                  className="textfield"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled
                      id="type"
                      error={!!errors.status}
                      helperText={errors?.status?.message}
                      variant="outlined"
                      type="text"
                      placeholder="Current Status"
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            className={classes.submitButton}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </form>
      </div>
    </>
  )
}

export default EditCustomMaterial
