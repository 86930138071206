import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { CircularProgress, Box } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import { TextField, Button, Grid } from "@mui/material"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { update } from "../../../../store/MappedEDPs/mappedEPDSlice"

import { styles } from "../../../../styles/Dashboard/data"
import { Autocomplete } from "@mui/material"
import {
  resetSelected,
  setLoading,
  list,
} from "../../../../store/Manufactures/manufactureSlice"
import { showMessage } from "../../../../store/Revalu/notificationSlice"
import { linkUserToManufacturer } from "../../../../store/InviteUser/inviteUserSlice"

const ManufacturerDetails = (props) => {
  const dispatch = useDispatch()
  const { id, classes, onClose, option = "epd", refreshPage = () => {} } = props
  const [saving, setSaving] = useState(false)

  const { data: manufacturerDataList, loading } = useSelector(
    ({ manufactures }) => manufactures.slice,
  )

  const handleChangeManufacturerAttribute = (e, manufacturerEntry) => {
    setValue("contactId", manufacturerEntry.id)
  }

  useEffect(() => {
    dispatch(list({ pageNo: 0, pageSize: 100000, searchValue: "" }))
  }, [])

  const schema = yup.object().shape({
    field: yup.string(),
    contactId: yup.string(),
  })

  const defaultValues = {
    field: "",
    contactId: "",
  }

  const {
    control,
    formState,
    handleSubmit,
    setError,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  })
  const { isValid, dirtyFields, errors } = formState

  const onSubmit = async (values) => {
    const manufactureCurrentId = values?.contactId
    try {
      setSaving(true)
      let res

      if (option === "epd") {
        res = await dispatch(
          update({
            fieldName: "manufacturerId",
            dataSourceId: id,
            value: manufactureCurrentId,
          }),
        )
      } else {
        res = await dispatch(linkUserToManufacturer(id, manufactureCurrentId))
        refreshPage()
      }

      if (option !== "epd") {
        dispatch(showMessage({ message: "Linked successfully" }))
        onClose()
      } else {
        if (res) {
          dispatch(showMessage({ message: "Linked successfully" }))
          onClose()
        } else {
          throw new Error("An unknown error occurred")
        }
      }
    } catch (ex) {
      console.error(ex)
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }))
    } finally {
      setSaving(false)
      onClose()
    }
  }

  return (
    <div className={classes.pageWrapper}>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.formWrapper}>
                <Autocomplete
                  onChange={handleChangeManufacturerAttribute}
                  fullWidth
                  // disablePortal
                  id="fieldName"
                  options={manufacturerDataList.content}
                  getOptionLabel={(option) => option.name}
                  getOptionKey={(option) => option.id}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      {...params}
                      label="Manufacturers"
                    />
                  )}
                />
              </div>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: "10px" }}
              disabled={!isValid}
              className={classes.submitButton}>
              {saving ? "Linking..." : "Link"}
            </Button>
          </Grid>
        </form>
      )}
    </div>
  )
}

export default withStyles(styles)(ManufacturerDetails)
