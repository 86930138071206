import React, { useState } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"
import { styles } from "../../styles/dataTable_new"
import withStyles from "@mui/styles/withStyles"

function DataTable_New(props) {
  const {
    classes,
    hideColumnsIndexes = [],
    headings,
    rowsData,
    allowActionButtons,
    ActionButtons,
    onRowClick = () => {},
    actionButtonsProps = {},
  } = props
  const [data, setData] = useState()

  const selectedItem = (val) => {
    setData(val)
    onRowClick(val)
  }

  return (
    <div className={classes.mainrapper}>
      <div className={classes.fieldswrapper}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headings
                  ? headings.map((name, index) =>
                      hideColumnsIndexes.indexOf(index) === -1 ? (
                        <TableCell key={name} align="center">
                          {name}
                        </TableCell>
                      ) : null,
                    )
                  : ""}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsData.map((row) => (
                <TableRow
                  key={row.name}
                  onClick={() => selectedItem(row)}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  {row.map((val, index) =>
                    hideColumnsIndexes.indexOf(index) === -1 ? (
                      <TableCell align="center" key={index}>
                        {val}
                      </TableCell>
                    ) : null,
                  )}
                  {allowActionButtons === true ? (
                    <TableCell align="center">
                      <ActionButtons
                        classes={classes}
                        data={data}
                        rowData={row}
                        {...actionButtonsProps}
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default withStyles(styles)(DataTable_New)
