import React from "react"
import { withStyles } from "@mui/styles"
import { warningItemStyles } from "../../components/Dashboard/EDPManagement/MappedEPDs/warningItemStyles"
import { Grid } from "@mui/material"

const WarningItem = ({ data, classes }) => {
  const { test_code, field, message, value, test_name, mv_test_name } = data

  return (
    <div className={classes.wrapper}>
      <p>
        {mv_test_name ? (
          <>
            <strong>Multivariate Test:</strong> <label>{mv_test_name}</label>
          </>
        ) : (
          <></>
        )}
        <br></br>
        <strong>Field:</strong> <label>{field}</label>
      </p>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <p>
            <strong>Code:</strong>{" "}
            <label className={classes.code}>{test_code}</label>
          </p>
          <p>
            <strong>Name:</strong>{" "}
            <label className={classes.code}>{test_name}</label>
          </p>
        </Grid>
        <Grid item md={8}>
          <p>
            <strong>Message:</strong> {message}
          </p>

          <p>
            <strong>Value:</strong> {value}
          </p>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(warningItemStyles)(WarningItem)
