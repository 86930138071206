import axios from "axios"
import { BASE_URL_CONTENT } from "../../config/config"
/* eslint-disable camelcase */

class SupportService {
  getAllSupportData = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_CONTENT + "support-page", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  createSupport = (data) => {
    const topics = [
      {
        topics: [
          {
            content: data?.content,
            title: data?.title,
          },
        ],
      },
    ]
    const reqData = {
      headline: data.headline,
      tiles: topics,
      languageCode: "eng",
    }

    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_CONTENT + "/support-page/create", reqData)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          const errors = {
            message: response.data.error,
          }
          reject([errors])
        })
    })
  }

  updateSupportData = (data) => {
    return new Promise((resolve, reject) => {
      if (data) {
        resolve(data)
      } else {
        reject(data)
      }
    })
  }

  updateSupport = (data) => {
    const topics = [
      {
        topics: data.newAllArr,
      },
    ]
    const reqData = {
      headline: data.supportDetail[0].headline,
      tiles: topics,
      languageCode: "eng",
      id: data.supportDetail[0].id,
    }

    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL_CONTENT + "support-page/update", reqData)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          const errors = {
            message: response.data.error,
          }
          reject([errors])
        })
    })
  }

  updateSupportHeadline = (data) => {
    const reqData = {
      headline: data.headline,
      tiles: data.supportDetail[0].tiles,
      languageCode: "eng",
      id: data.supportDetail[0].id,
    }

    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL_CONTENT + "support-page/update", reqData)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  addNewArray = (data) => {
    const topics = [
      {
        topics: data.finalArray,
      },
    ]
    const reqData = {
      headline: data.supportDetail[0].headline,
      tiles: topics,
      languageCode: "eng",
      id: data.supportDetail[0].id,
    }
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL_CONTENT + "support-page/update", reqData)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          const errors = {
            message: response.data.error,
          }
          reject([errors])
        })
    })
  }

  deleteSupportData = (id) => {
    const reqData = {
      supportPageId: id,
    }

    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL_CONTENT + "support-page/remove", reqData)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
}

const instance = new SupportService()

export default instance
