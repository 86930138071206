import React, { useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from "@mui/material"
import { Close } from "@mui/icons-material"
import { useDispatch } from "react-redux"

import { getAlertConfirm } from "../../store/AlertDialog/alertDialogSlice"

const AlertDialog = (props) => {
  const { title, message, onClose } = props
  const dispatch = useDispatch()

  const confirmation = async (value) => {
    const isConfirm = await dispatch(getAlertConfirm(value))
    if (value === false) {
      onClose(false)
    }

    return isConfirm
  }

  const {
    onCancel = () => confirmation(false),
    onConfirm = () => confirmation(true),
  } = props
  return (
    <div>
      <Dialog open={true} maxWidth="sm" fullWidth onClose={onCancel}>
        <DialogTitle>{title ? title : "Confirmation"}</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton size="large">{/* <Close /> */}</IconButton>
        </Box>
        <DialogContent>
          <Typography>{message ? message : ""}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="secondary" variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AlertDialog
