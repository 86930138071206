import withStyles from "@mui/styles/withStyles"

export const withMultipleStyles = (...params) => {
  return withStyles((theme) => {
    var styles = {}
    for (var len = params.length, key = 0; key < len; key++) {
      styles = Object.assign(styles, params[key](theme))
    }

    return styles
  })
}
