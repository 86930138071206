import * as React from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { IconButton, Menu, MenuItem } from "@mui/material"
import { styles } from "../../../styles/actionButtons"
import withStyles from "@mui/styles/withStyles"
import {
  setSelected,
  deleteAdminUser,
} from "../../../store/AdminUser/adminUserSlice"
import { showMessage } from "../../../store/Revalu/notificationSlice"
import { useDispatch, useSelector } from "react-redux"
import AlertDialog from "../../../common/AlertDialog/AlertDialog"
import { setConfirmation } from "../../../store/AlertDialog/alertDialogSlice"
import { resendOrCancelEmailUser } from "../../../store/InviteUser/inviteUserSlice"

const ITEM_HEIGHT = 48

function ActionButtons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [alert, setAlert] = React.useState(false)
  const open = Boolean(anchorEl)
  const { classes, rowData } = props
  const dispatch = useDispatch()

  const alertConfirmation = useSelector(
    ({ alert }) => alert.alertSlice.alertConfirm,
  )

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const resendOrCancelHandler = async (action) => {
    const data = [
      {
        email: rowData[2],
        firstName: rowData[1].split(" ")[0] || "",
        lastName: rowData[1].split(" ")[1] || "",
      },
    ]
    dispatch(resendOrCancelEmailUser(data, action))
    handleClose()
  }

  const showAlertDialogDelete = () => {
    setAlert(true)
    handleClose()
  }

  const deleteHandler = async () => {
    if (alertConfirmation == true) {
      try {
        await dispatch(deleteAdminUser(rowData[0]))
        dispatch(showMessage({ message: "Record deleted" }))
      } catch (ex) {
        dispatch(
          showMessage({
            message: ex.message ? ex.messge : ex,
            variant: "error",
          }),
        )
      } finally {
        setAlert(false)
      }
    }
  }

  const handleCancel = () => {
    dispatch(setConfirmation(false))
    setAlert(false)
  }

  return (
    <div className={classes.ActionButtons}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          marginTop: 33,
          maxHeight: ITEM_HEIGHT * 4.5,
          width: "20ch",
        }}>
        <MenuItem onClick={() => resendOrCancelHandler("resend")}>
          Resend Invite
        </MenuItem>
        <MenuItem onClick={() => resendOrCancelHandler("cancel")}>
          Cancel Invite
        </MenuItem>
      </Menu>

      {alert === true ? (
        <AlertDialog
          title="Delete Admin User"
          message="Are you sure want to delete this admin user?"
          onLoad={deleteHandler()}
          onCancel={handleCancel}
        />
      ) : (
        ""
      )}
    </div>
  )
}
export default withStyles(styles)(ActionButtons)
