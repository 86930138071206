import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { TextField, Button, Grid } from "@mui/material"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
  cancelButton: {
    display: "block",
    marginTop: 13,
    background: "red",
    color: "white",
    marginRight: 13,
    "& span": {
      padding: 0,
    },
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))
const CreateForm = ({ setIsCreateMode, addNewBuzzWord }) => {
  const classes = useStyles()
  const [definition, setDefinition] = useState("")
  const [word, setWord] = useState("")

  const saveHandler = () => {
    addNewBuzzWord({ definition, word })
    setIsCreateMode(false)
  }

  return (
    <div className={classes.wrapper}>
      <h1 className="title">Add new </h1>

      <Grid container>
        <Grid item xs={12} md={12}>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Word</label>
            <TextField
              type="text"
              className="textfield"
              onChange={(e) => {
                setWord(e.target.value)
              }}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Definition</label>
            <TextField
              type="text"
              className="textfield"
              onChange={(e) => {
                setDefinition(e.target.value)
              }}
            />
          </div>
        </Grid>
      </Grid>
      <div className={classes.buttonsWrapper}>
        <Button
          variant="contained"
          className={classes.cancelButton}
          onClick={() => setIsCreateMode(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={saveHandler}>
          Save Changes
        </Button>
      </div>
    </div>
  )
}

export default CreateForm
