import axios from "axios"
import { BASE_URL_V2 } from "../../config/config"
/* eslint-disable camelcase */

class ManufactureService {
  list = (data) => {
    const {
      pageNo = 0,
      pageSize = 10,
      searchValue = "",
      sortByName = false,
    } = data || {}
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "manufacturerNer/allPaginatedManufacturer?pageNo=" +
            pageNo +
            "&pageSize=" +
            pageSize +
            "&identifier=" +
            searchValue +
            "&sortByName=" +
            sortByName,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  changeBlockedStatus = (data) => {
    const { blocked, id } = data
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_V2("epd") +
            "manufacturerNer/change-manufacturer-block-status?id=" +
            id +
            "&isBlocked=" +
            blocked,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  updateFeaturedManufacturerStatus = (data) => {
    const { featuredManufacturer, id } = data
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_V2("epd") +
            "manufacturerNer/updateFeaturedManufacturer?manufacturerId=" +
            id +
            "&featuredManufacturer=" +
            featuredManufacturer,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  getById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") + "manufacturerNer/getManufacturerById?id=" + id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  create = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_V2("epd") + "manufacturerNer/create", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  update = (id, data) => {
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL_V2("epd") + "manufacturerNer/update?id=" + id, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  export = (fields) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          BASE_URL_V2("epd") +
            "manufacturerNer/downloadManufacturerNerFields?fields=" +
            fields,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  startExportProcess = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_V2("epd") + "epd-attribute-mapping/export-mapping-data")
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  getExportProcessStatus = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "epd-attribute-mapping/export-mapping-data/status",
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  deleteById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          BASE_URL_V2("epd") + "extracted/delete-by-id?dataSourceId=" + id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  qualityCheck = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          BASE_URL_V2("epd") +
            "qualityValidation/edp-quality-check?dataSourceId=" +
            id,
        )
        .then((response) => {
          if (response) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  statusUpdate = (data, id) => {
    const status = data.value.toUpperCase()
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_V2("epd") +
            "qualityValidation/manual-update-quality-status?dataSourceId=" +
            id +
            "&qualityStatusType=" +
            status,
        )
        .then((response) => {
          if (response) {
            resolve(response)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  getManufactureContact = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "manufacturerNer/getContactOfManufacturer?manufacturerId=" +
            id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  deleteManufactureContact = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          BASE_URL_V2("epd") + "manufacturerNer/deleteContactFromManufacturer",
          { data },
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  getManufactureSuppliers = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            "manufacturerNer/getSupplierOfAManufacturer?manufacturerId=" +
            id,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  deleteManufactureSupplier = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          BASE_URL_V2("epd") + "manufacturerNer/deleteSupplierFromManufacturer",
          { data },
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
}

const instance = new ManufactureService()

export default instance
