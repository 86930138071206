import React, { useState, useEffect } from "react"
import { styles } from "../../../../styles/Dashboard/data"
import withStyles from "@mui/styles/withStyles"
import { Button, Dialog, DialogTitle, Box } from "@mui/material"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import DataTable_New from "../../../../common/DataTable/dataTable_new"
import DrawerSideBar from "../../../../common/Drawer/drawer"
import UploadCSV from "./uploadCSV"
import CreateForm from "./createForm"
import {
  getAllSource,
  updateSelectedData_Source,
} from "../../../../store/Attributes/attributeSlice"
import { useSelector, useDispatch } from "react-redux"
import ActionButtons from "./actionButtons"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

const AllSource = (props) => {
  const [isCSVMode, setIsCSVMode] = useState(false)
  const [isCreateMode, setIsCreateMode] = useState(false)
  const [allowActionButtons, setAllowActionButtons] = useState(true)
  const [currentRecord, setCurrentRecord] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const { classes } = props

  const dispatch = useDispatch()

  const viewAllSource = useSelector(
    ({ attributes }) => attributes.attributeAll.attributeSourceData,
  )

  const getSelectedSourceData = useSelector(
    ({ attributes }) => attributes.attributeAll.sourceUpdateData,
  )

  useEffect(() => {
    const fetchData = async () => {
      let data = {}
      const isValid = await dispatch(getAllSource(data))
      // setLoading(false)
      return isValid
    }

    fetchData()

    getSelectedSourceData.length > 0 ? setShowModal(true) : ""
  }, [dispatch, getSelectedSourceData])

  const toggleModal = () => {
    setShowModal((prev) => !prev)
  }

  const hideModal = () => {
    setShowModal(false)
    setCurrentRecord(null)
    dispatch(updateSelectedData_Source(""))
  }

  const headings = ["ID", "Source Url"]
  let rowsData = viewAllSource.map((item) => {
    return [item.id, item.sourceUrl]
  })

  return (
    <div className={classes.pageWrapper}>
      <h1 className="title">All Source</h1>
      <div className={classes.headerContent}>
        <Button
          style={{ marginRight: 13 }}
          className={isCSVMode ? "lightButton" : "activeButton"}
          onClick={() => setIsCSVMode(false)}>
          Form
        </Button>
        {/* <Button
          className={isCSVMode ? "activeButton" : "lightButton"}
          onClick={() => setIsCSVMode(true)}>
          CSV File
        </Button> */}
        <div className={classes.headerOptions}>
          <Button
            style={{ marginLeft: 13 }}
            variant="contained"
            color="primary"
            onClick={toggleModal}
            startIcon={<AddCircleIcon style={{ color: "#c4cbda" }} />}>
            {getSelectedSourceData.length > 0
              ? "Update Here"
              : "Add new Source"}
          </Button>
        </div>
      </div>
      <DataTable_New
        headings={headings}
        rowsData={rowsData}
        allowActionButtons={allowActionButtons}
        ActionButtons={ActionButtons}
      />
      {/* {isCreateMode && (
        <DrawerSideBar open={isCreateMode}>
          {isCSVMode ? (
            <UploadCSV />
          ) : (
            <CreateForm getSelectedSourceData={getSelectedSourceData} />
          )}
        </DrawerSideBar>
      )} */}
      {showModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showModal}>
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>
                {getSelectedSourceData.length > 0 ? "Update" : "Create"} Source
              </Box>
              <Box textAlign="right">
                <IconButton onClick={hideModal} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <CreateForm
            currentRecord={getSelectedSourceData}
            onClose={hideModal}
          />
        </Dialog>
      )}
    </div>
  )
}

export default withStyles(styles)(AllSource)
