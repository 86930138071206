import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

// design
import { CircularProgress, Box } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import makeStyles from "@mui/styles/makeStyles"
import { TextField, Button, Grid } from "@mui/material"
import { styles } from "../../../../styles/Dashboard/data"
import { Autocomplete } from "@mui/material"
import { withMultipleStyles } from "../../../../styles/withMultipleStyles"

// redux
import { showMessage } from "../../../../store/Revalu/notificationSlice"
import { linkUserToManufacturer } from "../../../../store/InviteUser/inviteUserSlice"
import {
  resetSelected,
  list,
  update,
  getTags,
  addTagInMaterial,
  create,
} from "../../../../store/MaterialTags/materialTags"

const CreateTag = (props) => {
  const { id, classes, onClose, option = "", refreshPage = () => {} } = props
  const [tags, setTags] = useState([])
  const [tagsList, setTagsList] = useState([])
  const [saving, setSaving] = useState(false)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchTags = async () => {
      const response = await dispatch(getTags())
      setTagsList(response.content)
    }
    fetchTags()
  }, [])

  const getManufacturesName = () => {
    const names = tagsList?.map((m) => m.name)
    if (names.length) {
      const freshNames = names.filter((n) => n)
      setTags(freshNames)
    }
  }

  useEffect(() => {
    getManufacturesName()
  }, [tagsList])

  const useStyles = makeStyles((theme) => ({
    wrapper: {
      padding: 22,
      boxShadow: "0px 2px 5px #343D4F08",
      borderRadius: 12,
      "& .title": {
        fontSize: 17,
        fontWeight: 500,
        color: theme.palette.primary.fontColor,
      },
    },
    formWrapper: {
      marginBottom: 13,
      "& .textfield": {
        border: "1px solid " + theme.palette.drawer.border,
        borderRadius: 13,
        background: theme.palette.drawer.textFieldBg,
        "& input": {
          padding: "7px 18px",
          height: 11,
        },
      },
    },
    label: {
      color: theme.palette.primary.fontColor,
      fontWeight: 500,
      fontSize: 16,
      display: "block",
      marginBottom: 5,
    },
    submitButton: {
      marginLeft: "auto",
      display: "block",
      marginTop: 13,
      "& span": {
        padding: 0,
      },
    },
  }))

  const schema = yup.object().shape({
    materialTagDataSourceId: yup.string(),
    tagId: yup.string(),
  })

  const defaultValues = {
    materialTagDataSourceId: id,
    tagIds: [],
  }

  const {
    control,
    formState,
    handleSubmit,
    setError,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  })
  const { isValid, dirtyFields, errors } = formState

  const onSubmit = async (values) => {
    try {
      setSaving(true)
      let res = await dispatch(create(values))
      if (res) {
        dispatch(showMessage({ message: "Linked successfully" }))
        refreshPage()
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }))
    } finally {
      setSaving(false)
      onClose()
    }
  }

  const getIdFromManufactureName = (name) => {
    const currentManufacturer = tagsList?.filter((f) => f.name === name)
    return currentManufacturer[0]?.id
  }

  const handleChangeManufacturerAttribute = (e, name) => {
    const itemValue = getIdFromManufactureName(name)
    setValue("tagIds", [itemValue])
  }

  return (
    <div className={classes.pageWrapper}>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.formWrapper}>
                <Autocomplete
                  onChange={handleChangeManufacturerAttribute}
                  fullWidth
                  // disablePortal
                  id="fieldName"
                  options={tags}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      {...params}
                      label="Tags"
                    />
                  )}
                />
              </div>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: "10px" }}
              disabled={!isValid}
              className={classes.submitButton}>
              {saving ? "Adding" : "Add"}
            </Button>
          </Grid>
        </form>
      )}
    </div>
  )
}

export default withStyles(styles)(CreateTag)
