export const CURRENT_USER = "CURRENT_USER"

export const USER_STATUS = {
  USER_BLOCKED: "Blocked",
  BLOCKED: "Blocked",
  USER_REGISTRATION_APPROVED: "Approved",
  USER_REGISTRATION_PENDING: "Pending",
  USER_SIGN_OFF: "Sign off",
  INVITATION_URL_CLICKED: "Invitation URL Clicked",
  SECURITY_CODE_SENT: "Security Code Sent",
  EMAIL_VERIFIED: "Email Verified",
  INVITATION_SENT: "Invitation Sent",
  ACCOUNTED_CREATED: "Account Created",
}
export const USER_STATUS_REVERSED = {
  Blocked: "USER_BLOCKED",
  Approved: "USER_REGISTRATION_APPROVED",
  Pending: "USER_REGISTRATION_PENDING",
  "Sign off": "USER_SIGN_OFF",
}

export const USER_STATUS_REVERSED_IMPROVED = {
  Blocked: "BLOCKED",
  ACCOUNTED_CREATED: "ACCOUNTED_CREATED",
}

export const INVITE_USER_STATUS = {
  USER_REGISTRATION_INVITE_SENT: "Pending",
  USER_REGISTRATION_INVITE_ACCEPT: "Accepted",
}
