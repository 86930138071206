import * as React from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { IconButton, Menu, MenuItem } from "@mui/material"
import { styles } from "../../../../styles/actionButtons"
import withStyles from "@mui/styles/withStyles"

const ITEM_HEIGHT = 48

function ActionButtons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const { classes, rowData, onDownload = () => {} } = props

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDownload = (type) => {
    onDownload(type)
    handleClose()
  }

  return (
    <div className={classes.ActionButtons}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{
          marginTop: 10,
          maxHeight: ITEM_HEIGHT * 4.5,
          width: "20ch",
        }}>
        <MenuItem onClick={() => handleDownload("pdf")}>Download PDF</MenuItem>
        <MenuItem onClick={() => handleDownload("json")}>
          Download JSON
        </MenuItem>
      </Menu>
    </div>
  )
}
export default withStyles(styles)(ActionButtons)
