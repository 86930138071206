import axios from "axios"
import { BASE_URL_BUYER_SELLER } from "../../config/config"
/* eslint-disable camelcase */

class BuyerService {
  getAllBuyerData = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_BUYER_SELLER + "buyers", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
}

const instance = new BuyerService()

export default instance
