import React, { useState, useEffect } from "react"
import { styles } from "../../../styles/Dashboard/data"
import { styles as dataTableStyles } from "../../../styles/dataTable"
import {
  list,
  searchData,
  getEmail,
  deleteMaterial,
} from "../../../store/CustomMaterial/customMaterial"
import { useSelector, useDispatch } from "react-redux"
import {
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material"
import { showMessage } from "../../../store/Revalu/notificationSlice"
import MUIDataTable from "mui-datatables"
import { withMultipleStyles } from "../../../styles/withMultipleStyles"
import Search from "../EDPManagement/Search"
import ActionButtons from "./actionButtons"
import CloseIcon from "@mui/icons-material/Close"
import ViewDetails from "./viewDetails"
import Download from "./download"
import EditCustomMaterial from "./editCustomMaterial"
import AddUUIDinCustomMaterial from "./addUUIDinCustom"
import { DialogHeader } from "../Common/dialogHeader"

const CustomMaterial = (props) => {
  const [pageNo, setPageNo] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [selectedIdToView, setSelectedIdToView] = useState(null)
  const [selectedIdToEdit, setSelectedIdToEdit] = useState(null)
  const [sortBy, setSortBy] = useState({})
  const [selectIdToLinkUUID, setSelectIdToLinkUUID] = useState(null)

  const dispatch = useDispatch()
  const { data, loading, importLoading, importProcessStatus, searchValue } =
    useSelector(({ customMaterial }) => customMaterial.slice)
  const { totalElements } = data

  useEffect(() => {
    searchValue ? setPageNo(0) : pageNo
    dispatch(list({ pageNo, pageSize, searchValue, sortBy }))
  }, [pageNo, pageSize, searchValue, sortBy])

  const handleSortBy = (e, direction) => {
    const fieldNames = {
      "Material Name": "materialName",
      "Material Status": "customMaterialStatus",
      "Upload Date": "createdDate",
      "Last Update": "lastModifiedDate",
    }

    const directions = {
      asc: "ASC",
      desc: "DESC",
    }
    setSortBy({
      fieldName: fieldNames[e],
      direction: directions[direction],
    })
  }

  const handleViewDetails = (id) => {
    setSelectedIdToView(id)
  }

  const handleCloseDetails = () => {
    setSelectedIdToView(null)
  }

  const handleCloseEdit = () => {
    setSelectedIdToEdit(null)
  }

  const handleSelectedIdEdit = (id) => {
    setSelectedIdToEdit(id)
  }

  const handlerDelete = (id) => {
    dispatch(deleteMaterial(id))
  }

  const handleClose = () => {
    setSelectIdToLinkUUID(null)
  }

  const handleSelectIdUUID = (id) => {
    setSelectIdToLinkUUID(id)
  }

  const headers = [
    {
      name: "Material Name",
      options: {
        sort: true,
      },
    },
    {
      name: "Material Status",
      options: {
        sort: true,
      },
    },
    {
      name: "Upload Date",
      options: {
        sort: true,
      },
    },
    {
      name: "Last Update",
      options: {
        sort: true,
      },
    },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <ActionButtons
              onEdit={() => {}}
              onViewDetails={handleViewDetails}
              rowData={value}
              handlerDelete={handlerDelete}
              handleSelectedIdEdit={handleSelectedIdEdit}
              handleSelectIdUUID={handleSelectIdUUID}
            />
          )
        },
      },
    },
  ]
  const getParsedData = () => {
    return data?.content?.map((m) => {
      // const res = dispatch(getEmail(m?.userId))
      return [
        m?.materialName,
        m.customMaterialStatus,
        new Date(m.createdDate).toLocaleString("en-US"),
        new Date(m.lastModifiedDate).toLocaleString("en-US"),
        m,
      ]
    })
  }
  const onResponse = (res) => {
    if (!res) {
      dispatch(showMessage({ message: "No response", variant: "error" }))
      return false
    }
    const { status, statusText } = res
    if (Number(status) === 400) {
      dispatch(
        showMessage({
          message: statusText || "An uknown error occurred",
          variant: "error",
        }),
      )
    } else if (typeof res === "string") {
      dispatch(
        showMessage({
          message: res,
        }),
      )
    }
  }
  const { classes } = props

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50],
    onColumnSortChange: (col, direction) => handleSortBy(col, direction),
    onSearchClose: () => {
      dispatch(searchData({ search: "" }))
    },
    customSearchRender: (hideSearch) => {
      return (
        <Search
          classes={classes}
          hideSearch={hideSearch}
          onSearch={searchData}
          placeholder="Search Here..."
        />
      )
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state
      switch (action) {
        case "changePage":
          setPageNo(page)
          break
        case "changeRowsPerPage":
          setPageSize(rowsPerPage)
          break
      }
    },
  }

  return (
    <div className={classes.pageWrapper}>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.headerContent}>
        <div className={classes.headerOptions}>
          {importProcessStatus?.processing && (
            <div className={classes.notification}>
              Import process is in progress.
            </div>
          )}
        </div>
      </div>

      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <MUIDataTable
            title="Custom Material"
            columns={headers}
            data={getParsedData()}
            options={{ ...options, count: data?.totalElements }}
            onPageChange={() => {}}
            // className={classes.customMaterialDataTable}
          />
        </div>
      </div>
      {Boolean(selectedIdToView) && (
        <Dialog
          open={Boolean(selectedIdToView)}
          maxWidth="lg"
          fullWidth
          onClose={(event, reason) => {
            if (reason === "escapeKeyDown") {
              handleCloseDetails()
            }
          }}
          scroll="body">
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>User Details</Box>
              <Box textAlign="right">
                <IconButton onClick={handleCloseDetails} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <ViewDetails onClose={handleCloseDetails} id={selectedIdToView} />
        </Dialog>
      )}

      {Boolean(selectedIdToEdit) && (
        <Dialog
          open={Boolean(selectedIdToEdit)}
          maxWidth="lg"
          fullWidth
          scroll="body">
          <DialogHeader onClose={handleCloseEdit} title={"Change status"} />
          <EditCustomMaterial onClose={handleCloseEdit} id={selectedIdToEdit} />
        </Dialog>
      )}

      {Boolean(selectIdToLinkUUID) && (
        <Dialog
          open={Boolean(selectIdToLinkUUID)}
          maxWidth="lg"
          fullWidth
          onClose={handleClose}
          scroll="body">
          <DialogHeader
            onClose={handleClose}
            title={"Add UUID to Custom Material"}
          />
          <AddUUIDinCustomMaterial
            option="user"
            onClose={handleClose}
            id={selectIdToLinkUUID}
            // refreshPage={refreshPage}
          />
        </Dialog>
      )}
    </div>
  )
}

export default withMultipleStyles(styles, dataTableStyles)(CustomMaterial)
