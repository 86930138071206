import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import Upload from "rc-upload"
import FolderOpenIcon from "@mui/icons-material/FolderOpen"
import CloseIcon from "@mui/icons-material/Close"

const useStyles = makeStyles((theme) => ({
  uploadWrapper: {
    padding: 13,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
    "& .description": {
      fontSize: 15,
      color: theme.palette.primary.fontColor,
    },
  },
  uploadFile: {
    border: "2px dashed " + theme.palette.primary.main,
    width: "100%",
    display: "block",
    padding: 13,
    "&:focus": {
      outline: "none",
    },
    "& .content": {
      display: "flex",
      alignItems: "center",
      "& p": {
        margin: 0,
        color: theme.palette.primary.fontColor,
        fontSize: 13,
      },
      "& svg": {
        marginRight: 7,
        color: theme.palette.primary.main,
      },
    },
  },
  filesList: {
    marginTop: 17,
    "& .filename": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& .filetitle": {
        padding: 0,
        color: theme.palette.primary.fontColor,
      },
      "& svg": {
        color: theme.palette.primary.main,
        cursor: "pointer",
      },
    },
  },
}))
const UploadCSV = () => {
  const classes = useStyles()
  return (
    <div className={classes.uploadWrapper}>
      <h1 className="title">Uplaod a new CSV file</h1>
      <p className="description">You can upload one file at once</p>
      <Upload className={classes.uploadFile}>
        <div className="content">
          <FolderOpenIcon />
          <p>Drop file here to click to select from your pc</p>
        </div>
      </Upload>

      <div className={classes.filesList}>
        {["CSV 123123", "CSV 8712371"].map((item, index) => (
          <div key={index} className="filename">
            <span className="filetitle">{item}</span>
            <CloseIcon />
          </div>
        ))}
      </div>
    </div>
  )
}

export default UploadCSV
