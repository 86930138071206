// Contains the dialog header for MUI5

import { DialogTitle, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

export const DialogHeader = ({ onClose, title }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}>
        <CloseIcon />
      </IconButton>
    </>
  )
}
