import { createSlice } from "@reduxjs/toolkit"
import RegisteredUserService from "../../services/InvitedUserService/RegisteredUserService"
import { showMessage } from "../Revalu/notificationSlice"

export const getAllRegisteredUsers = (data) => async (dispatch) => {
  dispatch(setLoading(true))
  RegisteredUserService.getAllRegisteredUsers(data)
    .then((res) => {
      dispatch(getRegisteredUsersSuccess(res))
    })
    .catch((errors) => {
      console.error(errors)
    })
    .finally(() => {
      dispatch(setLoading(false))
      return true
    })
}

// METHODS FOR ADD/DELETE API KEY

export const addAPIKey =
  ({ apiKey, userId }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const res = await RegisteredUserService.addAPIKey({ apiKey, userId })
      dispatch(getAllRegisteredUsers())
      return res
    } catch (ex) {
      console.error(ex)
      throw ex
    } finally {
      dispatch(setLoading(false))
    }
  }
export const deleteAPIKey =
  ({ userId }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const res = await RegisteredUserService.deleteAPIKey({ userId })
      dispatch(getAllRegisteredUsers())
      return res
    } catch (ex) {
      console.error(ex)
      throw ex
    } finally {
      dispatch(setLoading(false))
    }
  }

// : METHODS FOR ADD/DELETE API KEY

export const exportUsers = () => async (dispatch) => {
  dispatch(setLoading(true))
  RegisteredUserService.exportUsers()
    .then((res) => {
      return res
    })
    .catch((errors) => {
      console.error(errors)
    })
    .finally(() => {
      dispatch(setLoading(false))
      return true
    })
}

export const deleteRegisteredUserNew = (data) => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    const res = await RegisteredUserService.deleteRegisteredUser(data)
    dispatch(showMessage({ message: "Deleted Successfully" }))
    dispatch(deleteRegisteredUsersFunc(data))
  } catch (error) {
    console.error(error)
  } finally {
    dispatch(setLoading(false))
  }
}

export const blockUnblockRegisteredUserNew = (data) => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    const res = await RegisteredUserService.blockUnblockRegisteredUser(data)
    dispatch(showMessage({ message: "Status Updated Successfully" }))
    dispatch(updateRegisteredUsersFunc(data))
  } catch (error) {
    console.error(error)
  } finally {
    dispatch(setLoading(false))
  }
}

export const blockUnblockRegisteredUser = (data) => async (dispatch) => {
  dispatch(setLoading(true))
  RegisteredUserService.blockUnblockRegisteredUser(data)
    .then(async (res) => {
      const { data, status, description } = res
      if (Boolean(data) && Number(status) === 200) {
        dispatch(showMessage({ message: description }))
        dispatch(updateRegisteredUsersFunc(data))
      } else {
        dispatch(showMessage({ message: description, variant: "error" }))
      }
      return res
    })
    .catch((errors) => {
      return errors
    })
    .finally(() => {
      dispatch(setLoading(false))
    })
}

const initialState = {
  success: false,
  data: {
    content: [],
    totalPages: 0,
    totalElements: 0,
  },
  loading: false,
  selected: null,
  searchValue: "",
}

const RegisteredUserSlice = createSlice({
  name: "registerUser/listing",
  initialState,
  reducers: {
    getRegisteredUsersSuccess: (state, action) => {
      state.success = true
      state.data = action.payload || {}
    },

    updateRegisteredUsersFunc: (state, action) => {
      const { id } = action.payload
      const index = state.data.findIndex(
        (f) => f.id.toString() === id.toString(),
      )
      if (index > -1) {
        state.data[index] = action.payload
      }
    },

    deleteRegisteredUsersFunc: (state, action) => {
      const { id } = action.payload
      const index = state.data.findIndex(
        (f) => f.id.toString() === id.toString(),
      )
      if (index > -1) {
        state.data.splice(index, 1)
      }
    },

    setLoading: (state, action) => {
      state.loading = action.payload
    },

    searchData: (state, action) => {
      let { search } = action.payload
      state.searchValue = search
    },

    setSelected: (state, action) => {
      const { id } = action.payload
      const index = state.data.findIndex(
        (f) => f.id.toString() === id.toString(),
      )
      if (index > -1) {
        state.selected = state.data[index]
      }
    },
    resetSelected: (state) => {
      state.selected = null
    },
  },
})

export const {
  getRegisteredUsersSuccess,
  setLoading,
  setSelected,
  resetSelected,
  updateRegisteredUsersFunc,
  deleteRegisteredUsersFunc,
  searchData,
} = RegisteredUserSlice.actions

export default RegisteredUserSlice.reducer
