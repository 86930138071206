import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import Upload from "rc-upload"
import FolderOpenIcon from "@mui/icons-material/FolderOpen"
import CloseIcon from "@mui/icons-material/Close"
import { upload } from "../../../../store/LCA/lcaSlice"
import { Button } from "@mui/material"
import { CircularProgress } from "@mui/material"
import { showMessage } from "../../../../store/Revalu/notificationSlice"
import { useSelector, useDispatch } from "react-redux"

const useStyles = makeStyles((theme) => ({
  uploadWrapper: {
    padding: 13,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
    "& .description": {
      fontSize: 15,
      color: theme.palette.primary.fontColor,
    },
  },
  uploadFile: {
    border: "2px dashed " + theme.palette.primary.main,
    width: "100%",
    display: "block",
    padding: 13,
    cursor: "pointer",
    "&:focus": {
      outline: "none",
    },
    "& .content": {
      display: "flex",
      alignItems: "center",
      "& p": {
        margin: 0,
        color: theme.palette.primary.fontColor,
        fontSize: 13,
      },
      "& svg": {
        marginRight: 7,
        color: theme.palette.primary.main,
      },
    },
  },
  filesList: {
    marginTop: 17,
    marginBottom: 10,
    "& .filename": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& .filetitle": {
        padding: 0,
        color: theme.palette.primary.fontColor,
      },
      "& svg": {
        color: theme.palette.primary.main,
        cursor: "pointer",
      },
    },
  },
}))
const UploadEPDs = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [files, setFiles] = useState([])
  const { uploadLoading } = useSelector(({ lca }) => lca.slice)

  const handleUpload = (file) => {
    setFiles((prev) => [...prev, file])
    return false
  }
  const hanldeRemoveFile = (uid) => {
    const currentFiles = [...files]
    const fileIndex = currentFiles.findIndex((f) => f.uid === uid)
    if (fileIndex > -1) {
      currentFiles.splice(fileIndex, 1)
    }
    setFiles(currentFiles)
  }
  const handleSubmit = async () => {
    try {
      const formData = new FormData()
      files.map((file) => {
        formData.append("files", file)
      })
      const res = await dispatch(upload(formData))
      const { data, status } = res
      if (status === 400) {
        dispatch(
          showMessage({
            message: data?.Exception || "Some error occurred. Try again!",
            variant: "error",
          }),
        )
      } else if (typeof res === "string" && res.indexOf("Uploaded") > -1) {
        dispatch(
          showMessage({
            message: `${
              files.length > 1 ? "Files" : "File"
            } uploaded successfully`,
          }),
        )
        setFiles([])
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }))
    }
  }

  return (
    <div className={classes.uploadWrapper}>
      <h1 className="title">Upload XML or JSON files</h1>
      <p className="description">You can upload multiple files at once</p>
      <Upload
        accept=".xml, .json"
        className={classes.uploadFile}
        multiple
        beforeUpload={handleUpload}>
        <div className="content">
          <FolderOpenIcon />
          <p>Drop file here to click to select from your pc</p>
        </div>
      </Upload>

      <div className={classes.filesList}>
        {files.map((item) => (
          <div key={item.uid} className="filename">
            <span className="filetitle">{item.name}</span>
            {!uploadLoading && (
              <CloseIcon onClick={() => hanldeRemoveFile(item.uid)} />
            )}
          </div>
        ))}
      </div>
      <Button
        variant="contained"
        color="primary"
        disabled={uploadLoading || !Boolean(files.length)}
        onClick={handleSubmit}>
        {uploadLoading ? (
          <>
            <CircularProgress size={20} />
            &nbsp; Uploading
          </>
        ) : (
          "Upload"
        )}
      </Button>
    </div>
  )
}

export default UploadEPDs
