import { createSlice } from "@reduxjs/toolkit"
import { CategoryEpdService } from "../../services/categoryEpdService"

export const startCategoryEpd = () => {
  return async (dispatch) => {
    dispatch(setCategoryEpdLoading(true))
    return CategoryEpdService.startCategoryEpd()
      .then((res) => {
        return res
      })
      .catch((errors) => {
        return errors
      })
      .finally(() => {
        dispatch(setCategoryEpdLoading(false))
      })
  }
}

export const startCategoryEpdId = (id) => {
  return async () => {
    return CategoryEpdService.startCategoryEpdId(id)
      .then((res) => {
        return res
      })
      .catch((errors) => {
        return errors
      })
  }
}

export const getCategoryEpdStatus = () => async (dispatch) => {
  return CategoryEpdService.getCategoryEpdStatus()
    .then((res) => {
      dispatch(setCategoryEpdStatus(res))
    })
    .catch((errors) => {
      dispatch(setCategoryEpdStatus(null))
    })
}

const initialState = {
  loading: false,
  categoryEpdStatus: null,
  categoryEpdLoading: false,
}

const categoryEpdSlice = createSlice({
  name: "categoryEpd/listing",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setCategoryEpdLoading: (state, action) => {
      state.categoryEpdLoading = action.payload
    },
    setCategoryEpdStatus: (state, action) => {
      state.categoryEpdStatus = action.payload
    },
  },
})

export const { setLoading, setCategoryEpdLoading, setCategoryEpdStatus } =
  categoryEpdSlice.actions

export default categoryEpdSlice.reducer
