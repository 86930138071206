import React, { useState, useEffect } from "react"
import { styles } from "../../../../styles/Dashboard/data"
import { styles as dataTableStyles } from "../../../../styles/dataTable"
import {
  importLCA,
  list,
  searchData,
  getImportProcessStatus,
} from "../../../../store/LCA/lcaSlice"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@mui/material"
import { CircularProgress } from "@mui/material"
import { showMessage } from "../../../../store/Revalu/notificationSlice"
import MUIDataTable from "mui-datatables"
import UploadEPDs from "./uploadEPDs"
import { withMultipleStyles } from "../../../../styles/withMultipleStyles"
import Search from "../Search"
import ActionButtons from "./actionButtons"

const OriginalEDPs = (props) => {
  const [pageNo, setPageNo] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const dispatch = useDispatch()
  const { data, loading, importLoading, importProcessStatus } = useSelector(
    ({ lca }) => lca.slice,
  )
  const { totalElements } = data

  useEffect(() => {
    dispatch(list({ pageNo, pageSize }))
  }, [pageNo, pageSize])

  useEffect(() => {
    const getStatus = () => {
      dispatch(getImportProcessStatus())
    }
    getStatus()
    const interval = setInterval(() => {
      getStatus()
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const handleDownload = (type) => {}

  const headers = [
    { name: "UUID", options: { sort: false } },
    { name: "Name", options: { sort: false } },
    { name: "Platform", options: { sort: false } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <ActionButtons
              onEdit={() => {}}
              onDownload={handleDownload}
              rowData={value}
            />
          )
        },
      },
    },
  ]
  const getParsedData = () => {
    return data?.content?.map((m) => [
      m?.processInformation?.dataSetInformation?.UUID,
      m?.processInformation?.dataSetInformation?.name?.baseName[0]?.value,
      m.platformName,
      m,
    ])
  }

  const onResponse = (res) => {
    if (!res) {
      dispatch(showMessage({ message: "No response", variant: "error" }))
      return false
    }
    const { status, statusText } = res
    if (Number(status) === 400) {
      dispatch(
        showMessage({
          message: statusText || "An uknown error occurred",
          variant: "error",
        }),
      )
    } else if (typeof res === "string") {
      dispatch(
        showMessage({
          message: res,
        }),
      )
    }
  }

  const handleImport = async () => {
    try {
      const res = await dispatch(importLCA())
      onResponse(res)
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }))
    }
  }
  const { classes } = props

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    rowsPerPageOptions: [7, 10, 12],
    onSearchClose: () => {
      dispatch(searchData({ search: "" }))
    },
    customSearchRender: (hideSearch) => {
      return (
        <Search
          classes={classes}
          hideSearch={hideSearch}
          onSearch={searchData}
          placeholder="Search in uuid and name"
        />
      )
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state
      switch (action) {
        case "changePage":
          setPageNo(page)
          break
        case "changeRowsPerPage":
          setPageSize(rowsPerPage)
          break
      }
    },
  }

  return (
    <div className={classes.pageWrapper}>
      <UploadEPDs />
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.headerContent}>
        <div className={classes.headerOptions}>
          {importProcessStatus?.processing && (
            <div className={classes.notification}>
              Import process is in progress.
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={importLoading || importProcessStatus?.processing}
            onClick={handleImport}>
            {importLoading ? (
              <>
                <CircularProgress size={20} />
                &nbsp; Please wait
              </>
            ) : (
              "Start Import Process"
            )}
          </Button>
        </div>
      </div>

      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <MUIDataTable
            title="Original EPDs"
            columns={headers}
            data={getParsedData()}
            options={{ ...options, count: totalElements }}
            onPageChange={() => {}}
          />
        </div>
      </div>
    </div>
  )
}

export default withMultipleStyles(styles, dataTableStyles)(OriginalEDPs)
