import { styled } from "@mui/styles"
import {
  Icon,
  IconButton,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material"
import { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { hideMessage } from "../../store/Revalu/notificationSlice"

import {
  CheckCircleOutline,
  PriorityHigh,
  ErrorOutline,
  Close,
} from "@mui/icons-material"

const StyledSnackbar = styled(Snackbar)(({ theme, variant }) => ({
  "& .notification-content": {
    maxWidth: 165,
    display: "flex",
    alignItems: "center",
    "& .contentBox": {
      display: "flex",
      alignItems: "baseline",
    },
    "& .MuiSnackbarContent-message": {
      flex: 8,
    },
    "& .MuiSnackbarContent-action": {
      flex: 2,
    },
    "& .icon": {
      "& svg": {
        fontSize: 17,
      },
    },
    ...(variant === "success" && {
      backgroundColor: "#43a047",
      color: "#FFFFFF",
    }),

    ...(variant === "error" && {
      backgroundColor: "#ff0052",
      color: "#FFFFFF",
    }),

    ...(variant === "info" && {
      backgroundColor: "blue",
      color: "#FFFFFF",
    }),

    ...(variant === "warning" && {
      backgroundColor: "",
      color: "#FFFFFF",
    }),
  },
}))

const variantIcon = {
  success: <CheckCircleOutline />,
  warning: <PriorityHigh />,
  error: <ErrorOutline />,
  info: <PriorityHigh />,
}

function RevaluNotification() {
  const dispatch = useDispatch()
  const state = useSelector(({ revalu }) => revalu.notification.state)
  const options = useSelector(({ revalu }) => revalu.notification.options)

  return (
    <StyledSnackbar
      {...options}
      open={state}
      onClose={() => dispatch(hideMessage())}
      ContentProps={{
        variant: "body2",
        headlineMapping: {
          body1: "div",
          body2: "div",
        },
      }}>
      <SnackbarContent
        className="notification-content"
        message={
          <div className="contentBox">
            {variantIcon[options.variant] && (
              <Icon className="icon" color="inherit">
                {variantIcon[options.variant]}
              </Icon>
            )}
            <Typography className="mx-8">{options.message}</Typography>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => dispatch(hideMessage())}
            size="large">
            <Close />
          </IconButton>,
        ]}
      />
    </StyledSnackbar>
  )
}

export default memo(RevaluNotification)
