export default function download(url, name) {
  if (!url || typeof url !== "string") {
    return
  }
  if (url.startsWith("https://storage.googleapis.com")) {
    const link = document.createElement("a")
    link.href = url
    link.target = "_blank"
    link.rel = "noreferrer"
    document.body.appendChild(link)
    link.click()
    setTimeout(() => {
      document.body.removeChild(link)
    }, 300)
  } else {
    const element = document.createElement("a")
    const file = new Blob([url], {
      type: "text/plain",
    })
    element.href = URL.createObjectURL(file)
    element.download = name
    element.target = "_blank"
    element.rel = "noreferrer"
    document.body.appendChild(element)
    element.click()
    setTimeout(() => {
      document.body.removeChild(element)
    }, 300)
  }
}

export const downloadPDF = async (url, name) => {
  if (!url || typeof url !== "string") {
    return Promise.reject(new Error("Invalid URL"))
  }

  try {
    // console.log(`A file with name ${name} is downloading...`)
    const element = document.createElement("a")
    element.href = url
    element.download = name
    element.target = "_blank"
    element.rel = "noreferrer"
    document.body.appendChild(element)
    element.click()
    setTimeout(() => {
      document.body.removeChild(element)
    }, 300)
    // console.log(`A file with name ${name} downloaded successfully`)
    return Promise.resolve() // Resolves the promise successfully
  } catch (error) {
    console.error("Error fetching the PDF:", error)
    // console.log(`Error in downloading a file with name ${name}`)
    return Promise.reject(error) // Rejects the promise with the error
  }
}
