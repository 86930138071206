import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  state: null,
  options: {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
    autoHideDuration: 6000,
    message: "Hi",
    variant: "success",
  },
}
const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.state = true
      state.options = {
        ...initialState.options,
        ...action.payload,
      }
    },
    hideMessage: (state, action) => {
      state.state = null
    },
  },
})

export const { hideMessage, showMessage } = notificationSlice.actions

export default notificationSlice.reducer
