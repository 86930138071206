import axios from "axios"
import { BASE_URL_V2 } from "../../config/config"
/* eslint-disable camelcase */

const ENDPOINT = "extracted"
const ENDPOINT_MAPPING = "epd-attribute-mapping"

class OriginalEPDService {
  list = (data) => {
    const { pageNo = 0, pageSize = 10 } = data || {}
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") +
            ENDPOINT +
            "/get-export-data?pageNo=" +
            pageNo +
            "&pageSize=" +
            pageSize,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  changeBlockedStatus = (data) => {
    const { blocked, id } = data
    return new Promise((resolve, reject) => {
      axios
        .post(
          BASE_URL_V2("epd") +
            ENDPOINT +
            "/epd-data-status?dataSourceId=" +
            id +
            "&isBlocked=" +
            blocked,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  export = (params) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") + ENDPOINT + "/extractAndDownloadSelectedFields",
          { params },
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  startExportProcess = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_V2("epd") + ENDPOINT_MAPPING + "/export-mapping-data")
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  getExportProcessStatus = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          BASE_URL_V2("epd") + ENDPOINT_MAPPING + "/export-mapping-data/status",
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  getById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_V2("epd") + "lca/query?identifier=" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  getOekobaudatById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_V2("epd") + "oekobaudat/get-by-id?identifier=" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  deleteById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL_V2("epd") + "oekobaudat/delete-by-id?identifier=" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  update = (data) => {
    const { attributeId, dataSourceId, value } = data
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL_V2("epd") +
            ENDPOINT +
            "/update/export-data?attributeId=" +
            attributeId +
            "&dataSourceId=" +
            dataSourceId +
            "&value=" +
            value,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
}

const instance = new OriginalEPDService()

export default instance
