import React from "react"
import { Route, Redirect } from "react-router-dom"
import PropTypes from "prop-types"

const PrivateRoute = ({ component: Component, granted, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        granted === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  )
}

PrivateRoute.defaultProps = {
  rest: {},
  location: {},
  granted: null,
}

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  granted: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  location: PropTypes.object,
  rest: PropTypes.object,
}

export default PrivateRoute
