import axios from "axios"
import { BASE_URL, BASE_URL_USER } from "../../config/config"
import { Routes } from "../../common/Constants/routes"
import { setToken } from "../../utils/token"
/* eslint-disable camelcase */

class AuthService {
  constructor() {
    this.setInterceptors()
    this.handleAuthentication()
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response.status === 401 || err.response.status === 403) {
            // if you ever get an unauthorized response, logout the user
            this.setSession(null)
            localStorage.clear()
            window.location.href = "/"
          }
          throw err
        })
      },
    )
  }

  handleAuthentication = () => {
    const access_token = this.getAccessToken()

    if (!access_token) {
      return
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token)
    } else {
      this.setSession(null)
    }
  }

  uploadLogo = (logo) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append("profileLogo", logo[0])
      axios
        .post(BASE_URL + "users/logo/upload", formData)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  signInWithEmailAndPassword = (email, password) => {
    const userData = {
      email: email,
      password: password,
      captchaResponse: "dashboard",
    }

    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_USER + "users/login?origin=dashboard", userData)
        .then((response) => {
          if (response?.data?.error) {
            const errors = {
              type: response?.data?.error?.includes("password")
                ? "password"
                : "email",
              message: response?.data?.error,
            }
            reject([errors])
          } else {
            localStorage.setItem("email_id", response.data.data.email)
            localStorage.setItem(
              "userAccountTypeId",
              response.data.data.userAccountTypeId,
            )
            this.setSession(response.data.data.userToken.sessionToken)
            resolve(response.data.data)
          }
        })
        .catch((error) => {
          const { response } = error
          const errors = {
            type: response?.data?.error?.includes("password")
              ? "password"
              : "email",
            message: response?.data?.error,
          }
          reject([errors])
        })
    })
  }

  resetPasswordRequest = (email) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL + "users/password/reset?email=" + email)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject("")
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  updateUserPassword = (password, passwordConfirm, token) => {
    const passwordChangeRequest = {
      password: password,
      repeatPassword: passwordConfirm,
    }
    return new Promise((resolve, reject) => {
      axios
        .put(
          BASE_URL + "users/password/update?token=" + token,
          passwordChangeRequest,
        )
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject("")
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/auth/access-token", {
          data: {
            access_token: this.getAccessToken(),
          },
        })
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.access_token)
            resolve(response.data.user)
          } else {
            this.logout()
            reject(new Error("Failed to login with token."))
          }
        })
        .catch((error) => {
          this.logout()
          reject(new Error("Failed to login with token."))
        })
    })
  }

  updateUserData = (user) => {
    return axios.post("/api/auth/user/update", {
      user,
    })
  }

  setSession = (access_token) => {
    if (access_token) {
      setToken(access_token)
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
    } else {
      localStorage.removeItem("jwt_access_token")
      delete axios.defaults.headers.common.Authorization
    }
  }

  logout = () => {
    this.setSession(null)
    localStorage.clear()
  }

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false
    }

    return true
  }

  getAccessToken = () => {
    return localStorage.getItem("jwt_access_token")
  }
}

const instance = new AuthService()

export default instance
