import React from "react"
import { Grid, Typography } from "@mui/material"
import { renderValue } from "../../../../../common/Helper/renderValue"

const AttributeDetails = ({ data, title }) => {
  const referenceToDataSource =
    data?.modellingAndValidation?.dataSourcesTreatmentAndRepresentativeness
      ?.referenceToDataSource

  return (
    <Grid container spacing={2}>
      {Boolean(title) && (
        <Grid item>
          <Typography variant="subTitle1" component="h2">
            {title}
          </Typography>
        </Grid>
      )}
      {data &&
        Object.keys(data)
          .sort((a, b) => {
            if (a < b) {
              return -1
            }
            if (a > b) {
              return 1
            }
            return 0
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={data[m] && data[m].length > 100 ? 12 : 6}
                    key={m}>
                    <strong>{m}:</strong> {renderValue(data[m])}
                  </Grid>
                  <br />
                </>
              )
            } else {
              return null
            }
          })}

      <Grid item xs={12} lg={12}>
        <h3>
          <u>LCIAResults</u>
        </h3>
      </Grid>
      {data?.LCIAResults?.LCIAResult &&
        Object.keys(data?.LCIAResults?.LCIAResult[0])
          .sort((a, b) => {
            if (a < b) {
              return -1
            }
            if (a > b) {
              return 1
            }
            return 0
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      data?.LCIAResults?.LCIAResult[0][m] &&
                      data?.LCIAResults?.LCIAResult[0][m].length > 100
                        ? 12
                        : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(data?.LCIAResults?.LCIAResult[0][m])}
                  </Grid>
                </>
              )
            } else {
              return null
            }
          })}
      <Grid item xs={12} lg={12}>
        <h3>
          <u>Administrative Information</u>
        </h3>
      </Grid>
      <Grid item xs={12} lg={12}>
        <h5>
          <u>Data Entry By</u>
        </h5>
      </Grid>
      {data?.administrativeInformation?.dataEntryBy &&
        Object.keys(
          data?.administrativeInformation?.dataEntryBy
            ?.referenceToDataSetFormat[0],
        )
          .sort((a, b) => {
            if (a < b) {
              return -1
            }
            if (a > b) {
              return 1
            }
            return 0
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      data?.administrativeInformation?.dataEntryBy
                        ?.referenceToDataSetFormat[0][m] &&
                      data?.administrativeInformation?.dataEntryBy
                        ?.referenceToDataSetFormat[0][m].length > 100
                        ? 12
                        : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(
                      data?.administrativeInformation?.dataEntryBy
                        ?.referenceToDataSetFormat[0][m],
                    )}
                  </Grid>
                </>
              )
            } else {
              return null
            }
          })}
      <Grid item xs={12} lg={12}>
        <h5>
          <u>Publication And Ownership</u>
        </h5>
      </Grid>
      {data?.administrativeInformation?.publicationAndOwnership &&
        Object.keys(data?.administrativeInformation?.publicationAndOwnership)
          .sort((a, b) => {
            if (a < b) {
              return -1
            }
            if (a > b) {
              return 1
            }
            return 0
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      data?.administrativeInformation?.publicationAndOwnership[
                        m
                      ] &&
                      data?.administrativeInformation?.publicationAndOwnership[
                        m
                      ].length > 100
                        ? 12
                        : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(
                      data?.administrativeInformation?.publicationAndOwnership[
                        m
                      ],
                    )}
                  </Grid>
                </>
              )
            } else {
              return null
            }
          })}

      <Grid item xs={12} lg={12}>
        <h3>
          <u>Exchanges</u>
        </h3>
      </Grid>
      {data?.exchanges?.exchange &&
        Object.keys(data?.exchanges?.exchange[0])
          .sort((a, b) => {
            if (a < b) {
              return -1
            }
            if (a > b) {
              return 1
            }
            return 0
          })
          .map((m) => {
            if (m !== "_id") {
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={
                      data?.exchanges?.exchange[0][m] &&
                      data?.exchanges?.exchange[0][m].length > 100
                        ? 12
                        : 6
                    }
                    key={m}>
                    <strong>{m}:</strong>{" "}
                    {renderValue(data?.exchanges?.exchange[0][m])}
                  </Grid>
                </>
              )
            } else {
              return null
            }
          })}

      <Grid item xs={12} lg={12}>
        <h3>
          <u>Modelling And Validation</u>
        </h3>
      </Grid>
      <Grid item xs={12} lg={6}>
        <h5>
          <u>LCI Method And Allocation:</u>
        </h5>
      </Grid>
      {data?.modellingAndValidation?.LCIMethodAndAllocation ? (
        <>
          <Grid item xs={12} lg={6}>
            <p>
              <b>refObjectId:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.modellingAndValidation?.LCIMethodAndAllocation
                    ?.referenceToLCAMethodDetails?.refObjectId
                }
              </span>
            </p>
            <p>
              <b>subType:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.modellingAndValidation?.LCIMethodAndAllocation?.other
                    ?.subType
                }
              </span>
            </p>
            <p>
              <b>type:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.modellingAndValidation?.LCIMethodAndAllocation
                    ?.referenceToLCAMethodDetails?.type
                }
              </span>
            </p>
          </Grid>
        </>
      ) : (
        ""
      )}
      <Grid item xs={12} lg={6}>
        <h5>
          <u>Compliance Declarations:</u>
        </h5>
      </Grid>
      {data?.modellingAndValidation?.complianceDeclarations ? (
        <>
          <Grid item xs={12} lg={6}>
            <p>
              <b>approvalOfOverallCompliance:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.modellingAndValidation?.complianceDeclarations
                    ?.compliance?.approvalOfOverallCompliance
                }
              </span>
            </p>
            <p>
              <b>refObjectId:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.modellingAndValidation?.complianceDeclarations
                    ?.compliance[0]?.referenceToComplianceSystem?.refObjectId
                }
              </span>
            </p>
            <p>
              <b>type:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.modellingAndValidation?.complianceDeclarations
                    ?.compliance[0]?.referenceToComplianceSystem?.type
                }
              </span>
            </p>
            <p>
              <b>uri:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.modellingAndValidation?.complianceDeclarations
                    ?.compliance[0]?.referenceToComplianceSystem?.uri
                }
              </span>
            </p>
          </Grid>
        </>
      ) : (
        ""
      )}
      <Grid item xs={12} lg={6}>
        <h5>
          <u>Data Sources Treatment And Representativeness:</u>
        </h5>
      </Grid>
      {data?.modellingAndValidation
        ?.dataSourcesTreatmentAndRepresentativeness ? (
        <>
          <Grid item xs={12} lg={6}>
            <h4>Reference To DataSource</h4>
            <p>
              <b>refObjectId:</b>
              <span style={{ marginLeft: "5px" }}>
                {referenceToDataSource[0]
                  ? referenceToDataSource[0].refObjectId
                  : referenceToDataSource
                    ? referenceToDataSource.refObjectId
                    : ""}
              </span>
            </p>
            <p>
              <b>type:</b>
              <span style={{ marginLeft: "5px" }}>
                {referenceToDataSource[0]
                  ? referenceToDataSource[0].type
                  : referenceToDataSource
                    ? referenceToDataSource.type
                    : ""}
              </span>
            </p>
            <p>
              <b>uri:</b>
              <span style={{ marginLeft: "5px" }}>
                {referenceToDataSource[0]
                  ? referenceToDataSource[0].uri
                  : referenceToDataSource
                    ? referenceToDataSource.uri
                    : ""}
              </span>
            </p>
            <p>
              <b>version:</b>
              <span style={{ marginLeft: "5px" }}>
                {referenceToDataSource[0]
                  ? referenceToDataSource[0].version
                  : referenceToDataSource
                    ? referenceToDataSource.version
                    : ""}
              </span>
            </p>

            <h4>Use Advice For DataSet</h4>
            {/* <p>
              <b>refObjectId:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.modellingAndValidation
                    ?.dataSourcesTreatmentAndRepresentativeness
                    ?.useAdviceForDataSet[0][""]
                }
              </span>
            </p> */}
            {/* <p>
              <b>lang:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.modellingAndValidation
                    ?.dataSourcesTreatmentAndRepresentativeness
                    ?.useAdviceForDataSet[0].lang
                }
              </span>
            </p> */}
          </Grid>
        </>
      ) : (
        ""
      )}
      <Grid item xs={12} lg={6}>
        <h5>
          <u>Validation:</u>
        </h5>
      </Grid>
      {data?.modellingAndValidation?.validation ? (
        <>
          <Grid item xs={12} lg={6}>
            <p>
              <b>refObjectId:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.modellingAndValidation?.validation?.review
                    ?.referenceToNameOfReviewerAndInstitution?.refObjectId
                }
              </span>
            </p>
            <p>
              <b>type:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.modellingAndValidation?.validation?.review
                    ?.referenceToNameOfReviewerAndInstitution?.type
                }
              </span>
            </p>
            <p>
              <b>uri:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.modellingAndValidation?.validation?.review
                    ?.referenceToNameOfReviewerAndInstitution?.uri
                }
              </span>
            </p>
          </Grid>
        </>
      ) : (
        ""
      )}

      <Grid item xs={12} lg={12}>
        <h3>
          <u>Process Information</u>
        </h3>
      </Grid>
      <Grid item xs={12} lg={6}>
        <h5>
          <u>DataSet Information:</u>
        </h5>
      </Grid>
      {data?.processInformation?.dataSetInformation ? (
        <>
          <Grid item xs={12} lg={6}>
            <p>
              <b>UUID</b>
              <span style={{ marginLeft: "5px" }}>
                {data?.processInformation?.dataSetInformation.UUID}
              </span>
            </p>
            <p>
              <b>baseName</b>
              <span style={{ marginLeft: "5px" }}>
                {data?.processInformation?.dataSetInformation?.name?.baseName}
              </span>
            </p>
            {/* <h4>Classification Information</h4> */}
            {/* <p>
              <b>name:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.processInformation?.dataSetInformation
                    ?.classificationInformation?.classification?.class[0][""]
                }
              </span>
            </p> */}
            {/* <p>
              <b>classId:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.processInformation?.dataSetInformation
                    ?.classificationInformation?.classification?.class[0]
                    .classId
                }
              </span>
            </p> */}
            {/* <p>
              <b>level:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.processInformation?.dataSetInformation
                    ?.classificationInformation?.classification?.class[0].level
                }
              </span>
            </p> */}
            {/* <h4>General Comment</h4>
            <p>
              <b>value:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.processInformation?.dataSetInformation
                    ?.generalComment[0][""]
                }
              </span>
            </p>
            <p>
              <b>lang:</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.processInformation?.dataSetInformation
                    ?.generalComment[0].lang
                }
              </span>
            </p> */}
          </Grid>
        </>
      ) : (
        ""
      )}
      <Grid item xs={12} lg={6}>
        <h5>
          <u>Geography:</u>
        </h5>
      </Grid>
      {data?.processInformation?.geography ? (
        <>
          <Grid item xs={12} lg={6}>
            <p>
              <b>location</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.processInformation?.geography
                    ?.locationOfOperationSupplyOrProduction?.location
                }
              </span>
            </p>
            {/* <p>
              <b>lang</b>
              <span style={{ marginLeft: "5px" }}>en</span>
            </p> */}
            {data?.processInformation?.geography
              ?.locationOfOperationSupplyOrProduction?.descriptionOfRestrictions
              ?.length &&
            data?.processInformation?.geography
              ?.locationOfOperationSupplyOrProduction
              ?.descriptionOfRestrictions[0][""] ? (
              <>
                <p>
                  <b>value</b>
                  <span style={{ marginLeft: "5px" }}>
                    {
                      data?.processInformation?.geography
                        ?.locationOfOperationSupplyOrProduction
                        ?.descriptionOfRestrictions[0][""]
                    }
                  </span>
                </p>
                <p>
                  <b>lang</b>
                  <span style={{ marginLeft: "5px" }}>
                    {
                      data?.processInformation?.geography
                        ?.locationOfOperationSupplyOrProduction
                        ?.descriptionOfRestrictions[0].lang
                    }
                  </span>
                </p>
              </>
            ) : (
              <p>
                <b>lang</b>
                <span style={{ marginLeft: "5px" }}>en</span>
              </p>
            )}
          </Grid>
        </>
      ) : (
        ""
      )}

      <Grid item xs={12} lg={6}>
        <h5>
          <u>Quantitative Reference:</u>
        </h5>
      </Grid>
      {data?.processInformation?.quantitativeReference ? (
        <>
          <Grid item xs={12} lg={6}>
            <p>
              <b>referenceToReferenceFlow</b>
              <span style={{ marginLeft: "5px" }}>
                {
                  data?.processInformation?.quantitativeReference
                    ?.referenceToReferenceFlow
                }
              </span>
            </p>
          </Grid>
        </>
      ) : (
        ""
      )}

      {/* <Grid item xs={12} lg={6}>
        <h5>
          <u>Technology</u>
        </h5>
      </Grid>
      {data?.processInformation?.technology ? (
        <>
          <Grid item xs={12} lg={6}>
            <h4>Reference To Technology Flow Diagramm Or Picture</h4>
            {data?.processInformation?.technology
              ?.referenceToTechnologyFlowDiagrammOrPicture[0] ? (
              <>
                <p>
                  <b>refObjectId</b>
                  <span style={{ marginLeft: "5px" }}>
                    {
                      data?.processInformation?.technology
                        ?.referenceToTechnologyFlowDiagrammOrPicture[0]
                        .refObjectId
                    }
                  </span>
                </p>
                <p>
                  <b>type</b>
                  <span style={{ marginLeft: "5px" }}>
                    {
                      data?.processInformation?.technology
                        ?.referenceToTechnologyFlowDiagrammOrPicture[0].type
                    }
                  </span>
                </p>
                <p>
                  <b>uri</b>
                  <span style={{ marginLeft: "5px" }}>
                    {
                      data?.processInformation?.technology
                        ?.referenceToTechnologyFlowDiagrammOrPicture[0].uri
                    }
                  </span>
                </p>
              </>
            ) : (
              ""
            )}
          </Grid>
        </>
      ) : (
        ""
      )} */}

      <Grid item xs={12} lg={6}>
        <h5>
          <u>Time</u>
        </h5>
      </Grid>
      {data?.processInformation?.time ? (
        <>
          <Grid item xs={12} lg={6}>
            <p>
              <b>dataSetValidUntil</b>
              <span style={{ marginLeft: "5px" }}>
                {data?.processInformation?.time?.dataSetValidUntil}
              </span>
            </p>
            <p>
              <b>referenceYear</b>
              <span style={{ marginLeft: "5px" }}>
                {data?.processInformation?.time?.referenceYear}
              </span>
            </p>
          </Grid>
        </>
      ) : (
        ""
      )}
    </Grid>
  )
}

export default AttributeDetails
