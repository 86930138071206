import React, { useState } from "react"
import { styles } from "../../../../styles/Dashboard/Pages/Support/index"
import { Grid, Button } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import CustomerService from "./Services"
import EditIcon from "@mui/icons-material/Edit"
import {
  updateSupportPageHeadline,
  submitSupportUpdateHeadline,
} from "../../../../store/Support/supportSlice"

const Faq = (props) => {
  const history = useHistory()
  const [headline, setHeadline] = useState("")
  const [isEditHeadline, setIsEditHeadline] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { classes, supportDetail, alertConfirmation } = props
  const dispatch = useDispatch()

  const editHeadlineHandler = async (data) => {
    setIsEditHeadline(true)
    const isValid = await dispatch(updateSupportPageHeadline(data))
    return isValid
  }

  const supportUpdateHeadline = useSelector(
    ({ support }) => support.supportAll.supportUpdateHeadlineData,
  )
  const onSubmitUpdateHeadline = async () => {
    setIsSaving(true)
    const data = {
      headline,
      supportDetail,
    }
    await dispatch(submitSupportUpdateHeadline(data, history))
    setIsEditHeadline(false)
    setIsSaving(false)
  }

  return isSaving ? (
    <div className={classes.updatingWrapper}>
      <span>Updating...</span>
    </div>
  ) : (
    <>
      <Grid container justifyContent="center" alignItems="center">
        {supportDetail.map((item, index) => (
          <Grid item xs={10} md={8} key={index}>
            <div className={classes.customerService}>
              {isEditHeadline && supportUpdateHeadline.length > 0 ? (
                <h5>
                  <input
                    type="text"
                    value={
                      headline
                        ? headline
                        : item?.headline && setHeadline(item?.headline)
                    }
                    onChange={(e) => setHeadline(e.target.value)}
                  />
                </h5>
              ) : (
                <h5>{item?.headline}</h5>
              )}

              <div className="actionButtons">
                {isEditHeadline && supportUpdateHeadline.length > 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmitUpdateHeadline}
                    className={classes.submitButton}>
                    Save
                  </Button>
                ) : (
                  <button onClick={() => editHeadlineHandler(item)}>
                    <EditIcon />
                  </button>
                )}
              </div>
            </div>
            <CustomerService
              servicesData={item}
              classes={classes}
              supportDetail={supportDetail}
              alertConfirmation={alertConfirmation}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default withStyles(styles)(Faq)
