const axiosRequest = (request) => {
  return new Promise((resolve, reject) => {
    request
      .then((response) => {
        if (response.data) {
          resolve(response.data)
        } else {
          reject(response.error)
        }
      })
      .catch((error) => {
        const { response } = error
        reject(response)
      })
  })
}

export default axiosRequest
