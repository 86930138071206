import React, { useState, useEffect } from "react"
import { styles } from "../../../../styles/Dashboard/data"
import { styles as dataTableStyles } from "../../../../styles/dataTable"
import {
  list,
  updateList,
  getExportProcessStatus,
  startExportProcess,
  searchData,
  qualityCheck,
} from "../../../../store/MappedEDPs/mappedEPDSlice"
import {
  startQualityValidationProcess,
  getQualityValidationProcessStatus,
} from "../../../../store/QualityValidation/qualityValidationSlice"
import { useSelector, useDispatch } from "react-redux"
import {
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  DialogContent,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import MUIDataTable from "mui-datatables"
import { withMultipleStyles } from "../../../../styles/withMultipleStyles"
import ActionButtons from "./actionButtons"
import ExportEPDs from "./exportEPDs"
import { showMessage } from "../../../../store/Revalu/notificationSlice"
import Search from "../Search"
import EditEPD from "./editEPD"
import ViewDetails from "./viewDetails"
import QualityStatusForm from "./qualityStatusForm"
import CategoryHierarchyForm from "./categoryHierarchyForm"
import ViewWarnings from "./viewWarnings"
import {
  getCategoryEpdStatus,
  startCategoryEpd,
  startCategoryEpdId,
} from "../../../../store/CategoryEpd/categoryEpdSlice"
import TableActionButton from "./tableActionButton"
import { addMaterial } from "../../../../store/CustomMaterial/customMaterial"
import ManufacturerDetails from "./manufacturerDetails"
import ViewErrors from "./viewErrors"
import UploadDocument from "./uploadDocument"
import ViewDocuments from "./viewDocuments"
import UploadReferenceDocument from "./uploadReferenceDocument"
import TagsDetails from "./tagsDetails"
import ViewTags from "./viewTags"
import CreateTag from "./createTag"
import ViewChangelog from "./viewChangelog"
import { DialogHeader } from "../../Common/dialogHeader"

const MappedEPDs = (props) => {
  const [pageNo, setPageNo] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const dispatch = useDispatch()
  const {
    data,
    loading,
    exportProcessStatus,
    exportProcessLoading,
    searchValue,
  } = useSelector(({ mappedEPD }) => mappedEPD.slice)
  const { qualityValidationProcessLoading, qualityValidationProcessStatus } =
    useSelector(({ qualityValidation }) => qualityValidation.slice)
  const { categoryEpdLoading, categoryEpdStatus } = useSelector(
    ({ categoryEpd }) => categoryEpd.slice,
  )
  const { totalElements } = data
  const [selectedId, setSelectedId] = useState(null)
  const [selectedIdToView, setSelectedIdToView] = useState(null)
  const [selectedIdToViewWarnings, setSelectedIdToViewWarnings] = useState(null)
  const [selectedIdToViewChangelog, setSelectedIdToViewChangelog] =
    useState(null)
  const [selectedIdToViewErrors, setSelectedIdToViewErrors] = useState(null)
  const [selectedIdToUploadDocument, setSelectedIdToUploadDocument] =
    useState(null)
  const [selectedIdToViewDocuments, setSelectedIdToViewDocuments] =
    useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showHierarchyModal, setShowHierarchyModal] = useState(false)
  const [qualityStatusValue, setQualityStatusValue] = useState("")
  const [mappedEdpID, setMappedEdpID] = useState("")
  const [showLinkingForm, setShowLinkingForm] = useState(false)
  const [sortEnum, setSortEnum] = useState("")
  const [referenceProject, setReferenceProject] = useState(false)
  const [selectedIdToReferencceProject, setSelectedIdToReferencceProject] =
    useState(null)

  // tags
  const [selectedIdToAddTag, setSelectedIdToAddTag] = useState(null)
  const [selectedIdToViewTag, setSelectedIdToViewTag] = useState(null)
  const [confirmEPDShowModal, setConfirmEPDShowModal] = useState(false)

  const [confirmQualityChkShowModal, setConfirmQualityChkShowModal] =
    useState(false)
  const [confirmExtractionShowModal, setConfirmExtractionShowModal] =
    useState(false)

  const handleAddTag = (id) => {
    setSelectedIdToAddTag(id)
  }

  const handleViewTag = (id) => {
    setSelectedIdToViewTag(id)
  }

  const handleCloseTag = () => {
    setSelectedIdToAddTag(null)
    setSelectedIdToViewTag(null)
  }

  const handleEnum = (e) => {
    switch (e) {
      case "Platform":
        sortEnum === "PLATFORM" ? setSortEnum("") : setSortEnum("PLATFORM")
        break
      case "Quality Status":
        sortEnum === "QUALITY_STATUS"
          ? setSortEnum("")
          : setSortEnum("QUALITY_STATUS")
        break
      case "Blocked?":
        sortEnum === "BLOCKED" ? setSortEnum("") : setSortEnum("BLOCKED")
        break
      default:
        setSortEnum("")
    }
  }

  const handleSelect = (id) => {
    setSelectedId(id)
  }
  const handleCloseEdit = () => {
    setSelectedId(null)
  }
  const handleViewDetails = (id) => {
    setSelectedIdToView(id)
  }
  const handleViewWarnings = (id) => {
    setSelectedIdToViewWarnings(id)
  }
  const handleViewErrors = (id) => {
    setSelectedIdToViewErrors(id)
  }
  const handleCloseDetails = () => {
    setSelectedIdToView(null)
    setShowLinkingForm(false)
  }
  const handleCloseWarnings = () => {
    setSelectedIdToViewWarnings(null)
  }
  const handleCloseErrors = () => {
    setSelectedIdToViewErrors(null)
  }

  const handleQualityCheck = (id) => {
    dispatch(qualityCheck(id))
  }

  const handleQualityStatus = (id, status) => {
    const value = {
      id,
      status,
    }
    setQualityStatusValue(value)
    setShowModal((prev) => !prev)
  }

  const handleCategoryHierarchy = (id, status) => {
    setMappedEdpID(id)
    setShowHierarchyModal((prev) => !prev)
  }

  const handleManufacturerLinking = (id) => {
    setSelectedIdToView(id)
    setShowLinkingForm(true)
  }

  const handleDocumentLinking = (id) => {
    setSelectedIdToUploadDocument(id)
  }

  const handleViewDocuments = (id) => {
    setSelectedIdToViewDocuments(id)
  }

  const hideModal = () => {
    setShowModal(false)
    setShowHierarchyModal(false)
    setSelectedIdToUploadDocument(null)
    setSelectedIdToViewDocuments(null)
    // dispatch(resetSelected())
  }

  const hideRefernceProject = () => {
    setReferenceProject(false)
  }

  const handleReferenceProject = (id) => {
    setSelectedIdToReferencceProject(id)
  }

  const throwMsg = (ex) => {
    dispatch(showMessage({ message: ex.message || ex, variant: "error" }))
  }
  const displayMsg = (res) => {
    if (res && typeof res === "string") {
      dispatch(showMessage({ message: res }))
    }
  }
  const handleStartExportProcess = async () => {
    setConfirmExtractionShowModal(false)
    try {
      const res = await dispatch(startExportProcess())
      displayMsg(res)
    } catch (ex) {
      throwMsg(ex)
    }
  }

  const handleAddMaterial = async () => {
    try {
      const res = await dispatch(addMaterial())
      if (res) {
        updateTable()
      }
      displayMsg(`Material added ${res}`)
    } catch (ex) {
      throwMsg(ex)
    }
  }

  const handleStartQualityValidationProcess = async () => {
    setConfirmQualityChkShowModal(false)
    try {
      const res = await dispatch(startQualityValidationProcess())
      getQualityValidationProcessStatusFunc()
      displayMsg(res)
    } catch (ex) {
      throwMsg(ex)
    }
  }

  const handleStartCategoryEpd = async () => {
    setConfirmEPDShowModal(false)
    try {
      const res = await dispatch(startCategoryEpd())
      getCategoryEpdStatusFunc()
      displayMsg(res)
    } catch (ex) {
      throwMsg(ex)
    }
  }

  const handleStartCategoryEpdId = async (id) => {
    try {
      const res = await dispatch(startCategoryEpdId(id))
      dispatch(
        showMessage({ message: "EPD categorization executed successfully" }),
      )
    } catch (ex) {
      throwMsg(ex)
    }
  }
  const handleStartCategoryEpdWithConfirmModal = () => {
    setConfirmEPDShowModal(true)
  }
  const handleStartQualityWithConfirmModal = () => {
    setConfirmQualityChkShowModal(true)
  }
  const handleStartExtractionWithConfirmModal = () => {
    setConfirmExtractionShowModal(true)
  }
  const EPDConfirmModalcloseHandler = () => {
    setConfirmEPDShowModal(false)
  }
  const QualityConfirmModalcloseHandler = () => {
    setConfirmQualityChkShowModal(false)
  }
  const ExtractConfirmModalcloseHandler = () => {
    setConfirmExtractionShowModal(false)
  }
  const updateTable = () => {
    dispatch(updateList({ pageNo, pageSize, searchValue, sortEnum }))
  }

  // Handles the sorting and the page nr and page size changes
  useEffect(() => {
    updateTable()
  }, [pageNo, pageSize, sortEnum])

  // If the search value changes set the page number to the first page
  useEffect(() => {
    setPageNo(0)
    updateTable()
  }, [searchValue])

  useEffect(() => {
    searchValue ? setPageNo(0) : pageNo
    dispatch(updateList({ pageNo, pageSize, searchValue }))
  }, [qualityValidationProcessStatus])

  useEffect(() => {
    const getStatus = () => {
      dispatch(getExportProcessStatus())
    }
    dispatch(getExportProcessStatus())
    const interval = setInterval(() => {
      if (!showLinkingForm) {
        getStatus()
      }
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const getQualityValidationProcessStatusFunc = () => {
    dispatch(getQualityValidationProcessStatus())
  }

  const getCategoryEpdStatusFunc = () => {
    dispatch(getCategoryEpdStatus())
  }

  useEffect(() => {
    getQualityValidationProcessStatusFunc()
    getCategoryEpdStatusFunc()
    const interval = setInterval(() => {
      if (!showLinkingForm) {
        getQualityValidationProcessStatusFunc()
        getCategoryEpdStatusFunc()
      }
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const headers = [
    {
      name: "Name",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(value.uuid)
              }}>
              {value.name}
            </div>
          )
        },
      },
    },
    { name: "Platform", options: { sort: true } },
    {
      name: "Quality Status",
      options: {
        sort: true,
        customBodyRender: (value) => {
          return value.qualiStatus === "Error" ||
            value.qualiStatus === "Warning" ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedIdToViewWarnings(value.uuid)
              }}>
              {value.qualiStatus}
            </div>
          ) : (
            <>{value.qualiStatus}</>
          )
        },
      },
    },
    { name: "Blocked?", options: { sort: true } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <ActionButtons
              onEdit={handleSelect}
              onViewDetails={handleViewDetails}
              onViewWarnings={handleViewWarnings}
              onViewErrors={handleViewErrors}
              onQualityCheck={handleQualityCheck}
              onCategoryEpd={handleStartCategoryEpdId}
              onQualityStatus={handleQualityStatus}
              onCategoryHierarchy={handleCategoryHierarchy}
              onManufacturerLinking={handleManufacturerLinking}
              onUploadDocument={handleDocumentLinking}
              onViewDocuments={handleViewDocuments}
              handleAddTag={handleAddTag}
              handleViewTag={handleViewTag}
              setSelectedIdToViewChangelog={setSelectedIdToViewChangelog}
              rowData={value}
            />
          )
        },
      },
    },
  ]
  const getParsedData = () => {
    return data?.content?.map((m) => [
      { name: m.Name_Product, uuid: m["Data Source ID"] },
      m?.platformName,
      {
        qualiStatus: m.qualityStatus ? m.qualityStatus : "Not Available",
        uuid: m["Data Source ID"],
      },
      m["blocked"] ? "Yes" : "No",
      { id: m["Data Source ID"], blocked: m.blocked, status: m.qualityStatus },
    ])
  }

  const { classes } = props

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    onColumnSortChange: (e) => handleEnum(e),
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50],
    page: pageNo,
    onSearchClose: () => {
      dispatch(searchData({ search: "" }))
    },
    customSearchRender: (hideSearch) => {
      return (
        <Search
          classes={classes}
          hideSearch={hideSearch}
          onSearch={searchData}
          placeholder="Search in name"
        />
      )
    },
    onTableChange: (action, state) => {
      const { page, rowsPerPage } = state
      switch (action) {
        case "changePage":
          setPageNo(page)
          break
        case "changeRowsPerPage":
          setPageSize(rowsPerPage)
          break
      }
    },
  }

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.headerContent}>
        <div className={classes.headerOptions}>
          <Box display="flex" width="50%" gap="10px" flexWrap="wrap">
            {qualityValidationProcessStatus?.processing && (
              <div className={classes.notification}>
                Quality check process is in progress.
              </div>
            )}
            {categoryEpdStatus?.processed && (
              <div className={classes.notification}>
                EPD Categorization process is in progress.
              </div>
            )}
            {exportProcessStatus?.processing && (
              <div className={classes.notification}>
                Extract process is in progress.
              </div>
            )}
          </Box>
          <Box
            display="inline-flex"
            justifyContent="flex-end"
            gap="10px"
            flexWrap="wrap">
            <TableActionButton
              onClick={handleStartCategoryEpdWithConfirmModal}
              disabled={categoryEpdLoading || categoryEpdStatus?.processed}
              loading={categoryEpdLoading}
              title="Start EPD categorization"
            />
            <TableActionButton
              onClick={handleStartQualityWithConfirmModal}
              disabled={
                qualityValidationProcessLoading ||
                qualityValidationProcessStatus?.processing
              }
              loading={qualityValidationProcessLoading}
              title="Start Quality Check Process"
            />
            <TableActionButton
              onClick={handleStartExtractionWithConfirmModal}
              disabled={exportProcessStatus?.processing || exportProcessLoading}
              loading={exportProcessLoading}
              title="Start Extract Process"
              color="secondary"
            />
            <ExportEPDs />
            <TableActionButton
              onClick={handleAddMaterial}
              title="Add Material"
            />
          </Box>
        </div>
      </div>

      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}

      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <MUIDataTable
            title="Mapped EPDs"
            columns={headers}
            data={getParsedData()}
            options={{ ...options, count: totalElements }}
            onPageChange={() => {}}
          />
        </div>
      </div>

      {Boolean(selectedIdToView) && showLinkingForm && (
        <Dialog
          open={Boolean(selectedIdToView)}
          maxWidth="lg"
          fullWidth
          onClose={(event, reason) => {
            if (reason === "escapeKeyDown") {
              handleCloseDetails()
            }
          }}
          scroll="body">
          <DialogHeader
            onClose={handleCloseDetails}
            title={"Manufacturer Linking"}
          />
          <ManufacturerDetails
            onClose={handleCloseDetails}
            setShowLinkingForm={setShowLinkingForm}
            id={selectedIdToView}
          />
        </Dialog>
      )}

      {Boolean(selectedIdToUploadDocument) && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={Boolean(selectedIdToUploadDocument)}>
          <DialogHeader onClose={hideModal} title={"Upload Document"} />
          <UploadDocument
            currentRecord={null}
            datasource={selectedIdToUploadDocument}
            onClose={hideModal}
            setReferenceProject={setReferenceProject}
            handleReferenceProject={handleReferenceProject}
          />
        </Dialog>
      )}

      {Boolean(referenceProject) && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideRefernceProject}
          open={Boolean(referenceProject)}>
          <DialogHeader
            onClose={hideModal}
            title={"Reference Project Document"}
          />
          <UploadReferenceDocument
            datasource={selectedIdToReferencceProject}
            onClose={hideRefernceProject}
          />
        </Dialog>
      )}

      {Boolean(selectedIdToViewDocuments) && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          maxWidth="lg"
          open={Boolean(selectedIdToViewDocuments)}>
          <DialogHeader onClose={hideModal} title={"View Documents"} />
          <ViewDocuments id={selectedIdToViewDocuments} onClose={hideModal} />
        </Dialog>
      )}

      {Boolean(selectedId) && !showLinkingForm && (
        <Dialog
          onClose={handleCloseEdit}
          maxWidth="lg"
          scroll="body"
          aria-labelledby="customized-dialog-title"
          open={Boolean(selectedId)}>
          <DialogHeader onClose={handleCloseEdit} title={"Update EPD"} />
          <DialogContent dividers>
            <EditEPD onClose={handleCloseEdit} id={selectedId} />
          </DialogContent>
        </Dialog>
      )}

      {Boolean(selectedIdToView) && !showLinkingForm && (
        <Dialog
          open={Boolean(selectedIdToView)}
          maxWidth="lg"
          fullWidth
          onClose={(event, reason) => {
            if (reason === "escapeKeyDown") {
              handleCloseDetails()
            }
          }}
          scroll="body">
          <DialogHeader onClose={handleCloseDetails} title={"EPD Details"} />
          <ViewDetails onClose={handleCloseDetails} id={selectedIdToView} />
        </Dialog>
      )}
      {Boolean(selectedIdToViewWarnings) && (
        <Dialog
          open={Boolean(selectedIdToViewWarnings)}
          maxWidth="lg"
          fullWidth
          onClose={(event, reason) => {
            if (reason === "escapeKeyDown") {
              handleCloseWarnings()
            }
          }}
          scroll="body">
          <DialogHeader onClose={handleCloseWarnings} title={"EPD Warnings"} />
          <ViewWarnings
            onClose={handleCloseWarnings}
            id={selectedIdToViewWarnings}
          />
        </Dialog>
      )}
      {Boolean(selectedIdToViewChangelog) && (
        <ViewChangelog
          onClose={setSelectedIdToViewChangelog}
          id={selectedIdToViewChangelog}
        />
      )}
      {Boolean(selectedIdToViewErrors) && (
        <Dialog
          open={Boolean(selectedIdToViewErrors)}
          maxWidth="lg"
          fullWidth
          onClose={(event, reason) => {
            if (reason === "escapeKeyDown") {
              handleCloseErrors()
            }
          }}
          scroll="body">
          <DialogTitle>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between">
              <Box>EPD Errors</Box>
              <Box textAlign="right">
                <IconButton onClick={handleCloseErrors} size="large">
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <ViewErrors onClose={handleCloseErrors} id={selectedIdToViewErrors} />
        </Dialog>
      )}
      {showModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showModal}>
          <DialogHeader onClose={hideModal} title={"Update Quality Status"} />
          <QualityStatusForm
            onClose={hideModal}
            qualityStatusValue={qualityStatusValue}
          />
        </Dialog>
      )}
      {showHierarchyModal && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={hideModal}
          open={showHierarchyModal}>
          <DialogHeader onClose={hideModal} title={"Category Hierarchy"} />
          <CategoryHierarchyForm
            onClose={hideModal}
            mappedEdpID={mappedEdpID}
          />
        </Dialog>
      )}

      {Boolean(selectedIdToAddTag) && (
        <Dialog
          open={Boolean(selectedIdToAddTag)}
          maxWidth="lg"
          fullWidth
          onClose={(event, reason) => {
            if (reason === "escapeKeyDown") {
              handleCloseTag()
            }
          }}
          scroll="body">
          <DialogHeader onClose={handleCloseTag} title={"Add Material Tag"} />
          <TagsDetails onClose={handleCloseTag} id={selectedIdToAddTag} />
        </Dialog>
      )}

      {Boolean(selectedIdToViewTag) && (
        <Dialog
          aria-labelledby="admin-user-form-dialog"
          onClose={handleCloseTag}
          maxWidth="lg"
          open={Boolean(selectedIdToViewTag)}>
          <DialogHeader onClose={handleCloseTag} title={"View Material Tag"} />
          <ViewTags id={selectedIdToViewTag} onClose={handleCloseTag} />
        </Dialog>
      )}
      {Boolean(confirmEPDShowModal) && (
        <Dialog
          open={Boolean(confirmEPDShowModal)}
          maxWidth="sm"
          scroll="body"
          onClose={EPDConfirmModalcloseHandler}>
          <DialogHeader
            onClose={EPDConfirmModalcloseHandler}
            title={"Do you want to start the EPD Categorization?"}
          />
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={EPDConfirmModalcloseHandler}>
              No
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleStartCategoryEpd}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {Boolean(confirmQualityChkShowModal) && (
        <Dialog
          open={Boolean(confirmQualityChkShowModal)}
          maxWidth="sm"
          onClose={QualityConfirmModalcloseHandler}
          scroll="body">
          <DialogHeader
            onClose={QualityConfirmModalcloseHandler}
            title={"Do you want to start the Quality Check Process?"}
          />
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={QualityConfirmModalcloseHandler}>
              No
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleStartQualityValidationProcess}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {Boolean(confirmExtractionShowModal) && (
        <Dialog
          open={Boolean(confirmExtractionShowModal)}
          maxWidth="sm"
          onClose={ExtractConfirmModalcloseHandler}
          scroll="body">
          <DialogHeader
            onClose={ExtractConfirmModalcloseHandler}
            title={"Do you want to start the Extraction (Mapping process)?"}
          />
          <DialogActions style={{ padding: "16px 24px" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={ExtractConfirmModalcloseHandler}>
              No
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleStartExportProcess}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}

export default withMultipleStyles(styles, dataTableStyles)(MappedEPDs)
