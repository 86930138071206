import { authReducer } from "./Auth/reducer"
import revalu from "./Revalu"
import user from "./Admin"
import seller from "./Seller"
import adminUser from "./AdminUser"
import registerUser from "./RegisteredUser"
import inviteUser from "./InviteUser"
import buyer from "./Buyer"
import orders from "./Orders"
import support from "./Support"
import objectType from "./ObjectType"
import lca from "./LCA"
import mappedEPD from "./MappedEDPs"
import alert from "./AlertDialog"
import attributes from "./Attributes"
import dashboard from "./Dashboard"
import qualityValidation from "./QualityValidation"
import categoryEpd from "./CategoryEpd"
import categoryHierarchy from "./CategoryHierarchy"
import customMaterial from "./CustomMaterial"
import manufactures from "./Manufactures"
import materialTags from "./MaterialTags"
import contacts from "./Contact"
import supplier from "./Supplier"
import { combineReducers } from "@reduxjs/toolkit"

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth: authReducer,
    revalu: revalu,
    user: user,
    seller: seller,
    buyer: buyer,
    orders: orders,
    support: support,
    adminUser,
    registerUser,
    inviteUser,
    lca,
    mappedEPD,
    objectType,
    qualityValidation,
    categoryEpd,
    alert: alert,
    manufactures,
    materialTags,
    contacts,
    supplier,
    attributes: attributes,
    dashboard: dashboard,
    categoryHierarchy: categoryHierarchy,
    customMaterial: customMaterial,
    ...asyncReducers,
  })

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === "auth/user/userLoggedOut") {
    state = undefined
  }

  return combinedReducer(state, action)
}

export default createReducer
