import { createTheme } from "@mui/material/styles"

export const styleTheme = createTheme({
  palette: {
    primary: { main: "#074880" },
  },
})

const DarkTheme = createTheme({
  palette: {
    primary: {
      main: "#7819ff",
      light: "#000930",
      lighter: "rgba(127, 17, 224, 0.3)",
      dark: "#7819ff",
      contrastText: "#FFFFFF",
      active: "#FFBB00",
      loginBg: "#131926",
      bg: "#131926",
      fontColor: "#e2e2e4",
      boldFont: "#ffffff",
      heading: "#ffffff",
      links: "#8833ff",
      required: "red",
      confirm: "#6b7a99",
      disable: "#dee1e8",
      textFieldBg: "#2d3c59",
    },
    summary: {
      background: "#202B40",
    },
    footer: {
      border: "#2d3c59",
    },
    menu: {
      background: "#131926",
      arrow: "#c4cbda",
      icons: "#c3cad9",
      border: "#2d3c59",
      active: {
        bg: "#2d3c59",
        color: "#8833ff",
      },
    },
    headers: {
      background: "#202b40",
      icons: "#4f5f81",
      button: {
        background: "#2d3c59",
        color: "#6b7a99",
      },
      activeButton: {
        background: "#8833ff",
        color: "#e2e2e4",
      },
      settings: {
        background: "#2D3C59",
        color: "#7D8FB3",
        border: "#59657D",
      },
      buttonGroup: {
        border: "#59657D",
        color: "#ADB8CC",
        background: "#2D3C59",
      },
    },
    drawer: {
      background: "#2d3c59",
      textFieldBg: "#202B40",
      border: "#59657D",
    },
  },
  dataTable: {
    background: "#2d3c59",
    color: "#6b7a99",
    border: "#6b7a99",
    hover: "#202b40",
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
  },
  overrides: {
    MuiButtonBase: {
      root: {
        borderRadius: "22px !important",
      },
    },
    MuiButton: {
      root: {
        padding: "5px 20px",
        textTransform: "capitalize",
      },
    },
    MuiFormControl: {
      root: {
        "&:focus": {
          outline: 0,
        },
      },
    },
    MuiTextField: {
      root: {
        "& .Mui-disabled": {
          backgroundColor: "#F5F5F5",
        },
      },
    },
    /**
     * START Mui-Datatables Plugin Styling
     */
    MuiPaper: {
      root: {
        boxShadow: "none !important",
      },
    },
    MuiTable: {
      root: {
        tableLayout: "fixed",
      },
    },
    MUIDataTable: {
      paper: {
        minHeight: 470,
        background: "#2d3c59",
      },
      responsiveScroll: {
        maxHeight: "none",
      },
    },
    MUIDataTableFooter: {
      root: {
        // display: "none",
      },
    },
    MuiTableHead: {
      root: {
        "& th": {
          border: "none",
          padding: "0px 15px",
          fontWeight: 500,

          "&.MUIDataTableSelectCell-headerCell": {
            padding: "5px 4px",
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: "0px 12px",
        color: "#6b7a99 !important",
      },
    },
    MUIDataTablePagination: {
      // display: "none",
      root: {
        // display: "none",
      },
    },
    /**
     * END Mui-Datatables Plugin Styling
     */
  },
})

export default DarkTheme
