import axios from "axios"
import { BASE_URL_V2 } from "../../config/config"
/* eslint-disable camelcase */

const ENDPOINT = "object-type"

class ObjectTypeService {
  list = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_V2("epd") + ENDPOINT + "/object-type/all", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  create = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_V2("epd") + ENDPOINT + "/create", data)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  update = (data, id) => {
    return new Promise((resolve, reject) => {
      axios
        .put(BASE_URL_V2("epd") + ENDPOINT + "/update?id=" + id, data)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  deleteByName = (name = "") => {
    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL_V2("epd") + ENDPOINT + "/delete?objectName=" + name)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
}

const instance = new ObjectTypeService()

export default instance
