import axios from "axios"
import { BASE_URL_V2 } from "../../config/config"

const ENDPOINT = "epdchangelog"

class EpdChangelogService {
  fetchEpdChangelog(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_V2("epd") + ENDPOINT + "/fetch?uuid=" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
}

const instance = new EpdChangelogService()

export default instance
