export const styles = (theme) => ({
  contactInfo: {
    textAlign: "center",
    marginTop: "50px",
    "@media(min-width: 960px)": {
      flexDirection: "column",
      justifyContent: "center",
    },
    "@media(max-width: 959px)": {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    "& h3": {
      fontSize: "33px",
      fontWeight: "400",
      lineHeight: "40px",
      color: "#212024",
      fontFamily: "Teodor TRIAL",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "21px",
      fontFamily: "Messina Sans Trial, sans-serif",
    },
  },
  customerService: {
    marginTop: "50px",
    marginBottom: "50px",
    "& h5": {
      fontSize: "18px",
      fontWeight: "600",
      color: "#212024",
      fontFamily: "Messina Sans Trial, sans-serif",
      float: "left",
      width: "75%",
      margin: 0,
      padding: 0,
      display: "contents",
      "& input": {
        fontSize: "18px",
        fontWeight: "600",
        "&:focus-visible": {
          outline: "none",
        },
      },
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      fontFamily: "Messina Sans Trial, sans-serif",
    },
    "& .actionButtons": {
      float: "right",
      width: "20%",
      textAlign: "right",
      margin: 0,
      padding: 0,
      "& button": {
        background: "transparent",
        border: "none",
        cursor: "pointer",
        padding: 0,
        marginLeft: 12,
      },
      "& .MuiButton-root": {
        background: "#7819ff",
      },
    },
  },
  services_accordion: {
    "& .MuiPaper-root": {
      boxShadow: "none",
      "& .MuiButtonBase-root": {
        padding: "0px",
        width: "auto",
      },
      "& .MuiAccordionSummary-expandIconWrapper": {
        flex: "unset",
        width: "2%",
      },
      "& .MuiIconButton-edgeEnd": {
        marginRight: 0,
      },
    },
    "& .actionButtons": {
      float: "right",
      width: 80,
      textAlign: "right",
      margin: 0,
      padding: 0,
      "& button": {
        background: "transparent",
        border: "none",
        cursor: "pointer",
        padding: 0,
        marginLeft: 12,
      },
    },
  },
  updatingWrapper: {
    padding: 32,
    width: "100%",
    height: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      fontSize: 32,
    },
  },
})
