export const styles = (theme) => ({
  searchWrapper: {
    display: "flex",
  },
  mainrapper: {
    background: theme.palette.primary.bg,
    "& tr": {
      background: theme.dataTable.background + " !important",
      borderBottom: "1px solid " + theme.dataTable.border,
      "& td": {
        border: "none !important",
        padding: "13px 15px",
        overflow: "hidden",
        "& svg": {
          fontSize: 17,
          curosr: "pointer",
        },
      },
      "& th": {
        color: theme.dataTable.color,
        padding: "13px 16px",
        textAlign: "left",
      },
      "&:hover": {
        background: theme.dataTable.hover + " !important",
      },
    },

    MuiTableBody: {
      root: {
        backgroundColor: theme.dataTable.background,
      },
    },
    MUIDataTableFooter: {
      MUIDataTablePagination: {
        root: {
          display: "none",
        },
      },
    },
  },
})
