import React, { useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { TextField, Button, Grid } from "@mui/material"
import { useDispatch } from "react-redux"
import {
  submitAddNew,
  submitSupportUpdate,
} from "../../../store/Support/supportSlice"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}))
const AddTile = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")

  const { supportDetail, supportUpdateDetail } = props

  const onSubmit = () => {
    const newData = { title, content }
    const oldData = supportDetail

    const finalArray = [...oldData[0].tiles[0].topics, newData]

    const data = {
      finalArray,
      supportDetail,
    }
    dispatch(submitAddNew(data, history))
  }

  const onSubmitUpdate = () => {
    const allArr = supportDetail[0].tiles[0].topics
    const newAllArr = [...allArr]
    const newObj = {
      title: title,
      content: content,
    }

    var index = allArr.findIndex(
      (item) =>
        item.title === supportUpdateDetail[0]?.title &&
        item.content === supportUpdateDetail[0]?.content,
    )

    newAllArr.splice(index, 1, newObj)

    const data = {
      newAllArr,
      supportDetail,
    }
    dispatch(submitSupportUpdate(data, history))
  }

  const onSubmitClose = () => {
    window.location.reload()
  }

  return (
    <div className={classes.wrapper}>
      <h1 className="title">
        {supportUpdateDetail.length > 0 ? "Edit Tile" : "Add New Tile"}
      </h1>

      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={12} md={12}>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Topic Title</label>
            <TextField
              type="text"
              className="textfield"
              value={
                title
                  ? title
                  : supportUpdateDetail.length > 0
                    ? supportUpdateDetail[0]?.title
                      ? supportUpdateDetail[0]?.title &&
                        setTitle(supportUpdateDetail[0]?.title)
                      : ""
                    : ""
              }
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Topic Content</label>
            <TextField
              type="text"
              className="textfield"
              value={
                content
                  ? content
                  : supportUpdateDetail.length > 0
                    ? supportUpdateDetail[0]?.content
                      ? supportUpdateDetail[0]?.content &&
                        setContent(supportUpdateDetail[0]?.content)
                      : ""
                    : ""
              }
              onChange={(e) => setContent(e.target.value)}
            />
          </div>
        </Grid>
      </Grid>

      {supportUpdateDetail.length > 0 ? (
        <>
          <Button
            variant="contained"
            color="primary"
            style={{ float: "left" }}
            onClick={onSubmitClose}
            className={classes.submitButton}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmitUpdate}
            className={classes.submitButton}>
            Update
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          className={classes.submitButton}>
          Add
        </Button>
      )}
    </div>
  )
}

export default AddTile
