import React, { useState, useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { TextField, Button, Grid, Select, MenuItem } from "@mui/material"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import { statusUpdate } from "../../../../store/MappedEDPs/mappedEPDSlice"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
// import PasswordField from "../../../common/Fields/PasswordField"
import { showMessage } from "../../../../store/Revalu/notificationSlice"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}))

const QualitystatusForm = ({ qualityStatusValue, onClose = () => {} }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [statusValue, setStatusValue] = useState("")
  const history = useHistory()
  const { recordLoading } = useSelector(({ objectType }) => objectType.slice)
  const id = qualityStatusValue?.id
  const defaultValues = {
    name: qualityStatusValue?.status || "",
  }

  const schema = yup.object().shape({
    name: yup.string().required("Required"),
  })

  const { control, formState, handleSubmit, setError, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  })
  const { isValid, errors } = formState

  const onResponse = (res) => {
    const { id, status, data } = res
    if (Number(status) === 400) {
      dispatch(showMessage({ message: "Error", variant: "error" }))
      onClose()
    }
    dispatch(showMessage({ message: "Updated successfully" }))
    onClose()
    window.location.reload()
  }

  const onSubmit = async (model) => {
    const status = {
      value: statusValue ? statusValue : model.name,
    }
    const res = await dispatch(statusUpdate(status, id, history))
    onResponse(res)
  }

  const onChangeStatus = (event) => {
    setStatusValue(event.target.value)
  }

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Status*</label>
              {/* <Controller
                name="name"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    placeholder="Name"
                    required
                  />
                )}
              /> */}
              <Controller
                name="name"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <Select
                    error={!!errors.name}
                    labelId="demo-simple-select-label"
                    variant="outlined"
                    value={
                      statusValue ? statusValue : qualityStatusValue?.status
                    }
                    label="Name"
                    onChange={onChangeStatus}>
                    <MenuItem value={"Success"}>Success</MenuItem>
                    <MenuItem value={"Warning"}>Warning</MenuItem>
                  </Select>
                )}
              />
            </div>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || recordLoading}
          className={classes.submitButton}>
          {recordLoading ? "Saving..." : "Save"}
        </Button>
      </form>
    </div>
  )
}

export default QualitystatusForm
