import axios from "axios"
import { BASE_URL_EPD } from "../../config/config"
import axiosRequest from "../axiosReq"
/* eslint-disable camelcase */

const END_POINT = "extracted/"
class CategoryEpdService {
  startCategoryEpd = () => {
    const req = axios.post(BASE_URL_EPD + `${END_POINT}start-category-fetch`)
    return axiosRequest(req)
  }
  startCategoryEpdId = (id) => {
    const req = axios.post(
      BASE_URL_EPD +
        `${END_POINT}automatic-category-fetch${id ? "?id=" + id : ""}`,
    )
    return axiosRequest(req)
  }
  getCategoryEpdStatus = () => {
    const req = axios.get(BASE_URL_EPD + `${END_POINT}categoryMatch/status`)
    return axiosRequest(req)
  }
}

const instance = new CategoryEpdService()

export default instance
