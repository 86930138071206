import axios from "axios"
import { BASE_URL_V2 } from "../../config/config"
/* eslint-disable camelcase */

const ENDPOINT = "qualityValidation"

class QualityValidationService {
  startQualityValidationProcess = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(BASE_URL_V2("epd") + ENDPOINT + "/start-quality-check-process")
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  getQualityValidationProcessStatus = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_V2("epd") + ENDPOINT + "/quality-data/status")
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }

  getWarningsById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_V2("epd") + ENDPOINT + "/get-by-id?dataSourceId=" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
  getErrorssById = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(BASE_URL_V2("epd") + ENDPOINT + "/get-by-id?dataSourceId=" + id)
        .then((response) => {
          if (response.data) {
            resolve(response.data)
          } else {
            reject(response.error)
          }
        })
        .catch((error) => {
          const { response } = error
          reject(response)
        })
    })
  }
}

const instance = new QualityValidationService()

export default instance
