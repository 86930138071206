import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { CircularProgress, Box } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import makeStyles from "@mui/styles/makeStyles"
import { TextField, Button, Grid } from "@mui/material"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"

import { styles } from "../../../../styles/Dashboard/data"
import {
  resetSelected,
  setLoading,
  deleteManufactureSupplier,
  getManufactureSuppliers,
  getManufactureContact,
} from "../../../../store/Manufactures/manufactureSlice"
import { showMessage } from "../../../../store/Revalu/notificationSlice"
import { withMultipleStyles } from "../../../../styles/withMultipleStyles"
import MUIDataTable from "mui-datatables"

const ViewAllSuppliers = (props) => {
  const dispatch = useDispatch()
  const { id, classes, onClose } = props
  const [suppliers, setSuppliers] = useState([])
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const refresh = () => setUpdate((prev) => setUpdate(!prev))

  useEffect(() => {
    const fetchSuppliers = async () => {
      setLoading(true)
      const res = await dispatch(getManufactureSuppliers(id))
      setLoading(false)
      if (res.length) {
        setSuppliers(res)
      } else {
        setLoading(false)
        dispatch(showMessage({ message: "No Suppliers Found" }))
      }
    }
    fetchSuppliers()
  }, [id, dispatch, update])

  const deleteSupplier = async (values) => {
    const data = {
      manufacturerId: id,
      supplierId: values?.id,
    }
    setLoading(true)
    const res = await dispatch(deleteManufactureSupplier(data))
    setLoading(false)
    if (res) {
      refresh()
      dispatch(showMessage({ message: "Unlink Successfully" }))
    } else {
      setLoading(false)
      dispatch(showMessage({ message: "No Suppliers Found" }))
    }
  }

  const useStyles = makeStyles((theme) => ({
    wrapper: {
      padding: 22,
      boxShadow: "0px 2px 5px #343D4F08",
      borderRadius: 12,
      "& .title": {
        fontSize: 17,
        fontWeight: 500,
        color: theme.palette.primary.fontColor,
      },
    },
    formWrapper: {
      marginBottom: 13,
      "& .textfield": {
        border: "1px solid " + theme.palette.drawer.border,
        borderRadius: 13,
        background: theme.palette.drawer.textFieldBg,
        "& input": {
          padding: "7px 18px",
          height: 11,
        },
      },
    },
    label: {
      color: theme.palette.primary.fontColor,
      fontWeight: 500,
      fontSize: 16,
      display: "block",
      marginBottom: 5,
    },
    submitButton: {
      marginLeft: "auto",
      display: "block",
      marginTop: 13,
      "& span": {
        padding: 0,
      },
    },
  }))

  const headers = [
    { name: "Name", options: { sort: true } },
    { name: "Email", options: { sort: false } },
    { name: "TelephoneNo", options: { sort: false } },
    { name: "Country", options: { sort: false } },
    { name: "City", options: { sort: false } },
    { name: "Street", options: { sort: false } },
    { name: "Zip Code", options: { sort: false } },
    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => deleteSupplier(value)}>
              Unlink
            </Button>
          )
        },
      },
    },
  ]

  const getParsedData = () => {
    return suppliers?.map((m) => [
      m.contactName,
      m?.email,
      m?.telephoneNo,
      m?.country,
      m?.city,
      m?.street,
      m?.zipCode,
      {
        id: m?.id,
      },
    ])
  }

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    search: false,
    pagination: false,
  }

  return (
    <div className={classes.pageWrapper}>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <MUIDataTable
            title=""
            columns={headers}
            data={getParsedData()}
            options={{ ...options }}
          />
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(ViewAllSuppliers)
