import React, { useContext, useState } from "react"
import { styles } from "../../styles/navbar"
import withStyles from "@mui/styles/withStyles"
import UserIcon from "@mui/icons-material/PersonOutline"
import OrdersIcon from "@mui/icons-material/AddToHomeScreen"
import SettingsIcon from "@mui/icons-material/Settings"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import UserContext from "../../context/UserContext"
import { useLocation, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { Routes } from "../Constants/routes"

const Navbar = (props) => {
  const location = useLocation()
  const { state } = location
  const [isDarkMode, setIsDarkMode] = useState(false)
  const {
    actions: { setDarkTheme, setLighTheme },
  } = useContext(UserContext)
  const { classes } = props

  const history = useHistory()

  const setThemeMode = () => {
    setIsDarkMode(!isDarkMode)
    if (!isDarkMode) {
      setLighTheme()
    } else {
      setDarkTheme()
    }
  }

  return (
    <div className={classes.navbarWrapper}>
      <div className={classes.headingWithMenu}>
        <h1>{state?.title}</h1>
        <div className={classes.navIcons}>
          {/* <NotificationsIcon /> */}
          <UserIcon onClick={() => history.push(Routes.user.platformUsers)} />
          <OrdersIcon onClick={() => history.push(Routes.lca.mappedEPDs)} />
          <SettingsIcon onClick={setThemeMode} />
          <AccountCircleIcon />
        </div>
      </div>
    </div>
  )
}

export default withRouter(connect()(withStyles(styles)(Navbar)))
