import React, { useState, useEffect, Fragment } from "react"
import { useDispatch } from "react-redux"
import { styles } from "../../../../styles/Dashboard/data"
import { CircularProgress, Box, Dialog, Grid } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import { fetchEpdChangelog } from "../../../../store/EpdChangelog/epdChangelogSlice"
import { DialogHeader } from "../../Common/dialogHeader"

const ViewChangelog = (props) => {
  const dispatch = useDispatch()
  const { id, classes, onClose = () => {} } = props
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (id) {
      ;(async () => {
        try {
          const res = await dispatch(fetchEpdChangelog(id))
          if (res) {
            if (res) {
              setData(res)
            }
          }
        } catch (ex) {
          dispatch(showMessage({ message: ex.message || ex, variant: "error" }))
        } finally {
          setLoading(false)
        }
      })()
    }
  }, [dispatch, id])
  return (
    <Dialog
      open={Boolean(id)}
      maxWidth="lg"
      fullWidth
      onClose={(event, reason) => {
        onClose(null)
      }}
      scroll="body">
      <DialogHeader onClose={() => onClose(null)} title="EPD Changelog" />
      <div
        className={classes.pageWrapper}
        style={{ marginTop: -35, paddingTop: 0 }}>
        <section style={{ borderBottom: "1px dotted" }}>
          {loading ? (
            <Box textAlign="center">
              <CircularProgress color="primary" />
            </Box>
          ) : !data.length ? (
            <p>No changes</p>
          ) : (
            <Fragment>
              {Boolean(data.length) &&
                data.map((m) => (
                  <Grid key={m.id} container spacing={2}>
                    <Grid item xs={4}>
                      <p>
                        <strong>Action:</strong>{" "}
                        <label className={classes.code}>{m.action}</label>
                      </p>
                      <p>
                        <strong>Field Name:</strong>{" "}
                        <label className={classes.code}>{m.field_name}</label>
                      </p>
                    </Grid>
                    <Grid item md={4}>
                      <p>
                        <strong>Original Value:</strong> {m.original_value}
                      </p>

                      <p>
                        <strong>Updated Value:</strong> {m.update_value}
                      </p>
                    </Grid>
                    <Grid item md={4}>
                      <p>
                        <strong>Date:</strong>{" "}
                        {new Date(parseInt(m.created)).toUTCString()}
                      </p>
                      <p>
                        <strong>User Id:</strong> {m.performed_by}
                      </p>
                    </Grid>
                  </Grid>
                ))}
            </Fragment>
          )}
        </section>
      </div>
    </Dialog>
  )
}

export default withStyles(styles)(ViewChangelog)
