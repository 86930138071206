/* eslint-disable prettier/prettier */
import axios from "axios"

// Same for all environments
export const BASE_HOST = "revalu-dev-user.i-ways-network.org"
export const BASE_HOST_BUYER_SELLER =
  "revalu-dev-seller-buyer.i-ways-network.org"
export const BASE_HOST_PRODUCT = "revalu-dev-product.i-ways-network.org"
export const BASE_HOST_CONTENT = "revalu-dev-content.i-ways-network.org"

// ====================================================

export const BASE_URL = "https://" + BASE_HOST + "/"
export const BASE_URL_BUYER_SELLER = "https://" + BASE_HOST_BUYER_SELLER + "/"
export const BASE_URL_PRODUCT = "https://" + BASE_HOST_PRODUCT + "/"
export const BASE_URL_CONTENT = "https://" + BASE_HOST_CONTENT + "/"

// Platform specific exports

// DEV
const BASE_HOST_USER_MANAGEMENT_DEV =
  "user-management-dev.revalu.i-ways-network.org"
const BASE_HOST_EPD_MANAGEMENT_DEV = "epd-dev.revalu.i-ways-network.org"
const BASE_HOST_V2_DEV = "dev.revalu.i-ways-network.org"
const BASE_HOST_ADMIN_USERS_DEV =
  "https://user-management-dev.revalu.i-ways-network.org"

  // DEV REVALU
const BASE_HOST_USER_MANAGEMENT_DEV_REVALU =
  "user-management-dev.revalu.world"
const BASE_HOST_EPD_MANAGEMENT_DEV_REVALU = "epd-dev.revalu.world"
const BASE_HOST_V2_DEV_REVALU = "dev.revalu.world"
const BASE_HOST_ADMIN_USERS_DEV_REVALU =
  "https://user-management-dev.revalu.world"

// STAGE
const BASE_HOST_USER_MANAGEMENT_STAGE =
  "user-management-stage.revalu.i-ways-network.org"
const BASE_HOST_EPD_MANAGEMENT_STAGE = "epd-stage.revalu.i-ways-network.org"
const BASE_HOST_V2_STAGE = "stage.revalu.i-ways-network.org"
const BASE_HOST_ADMIN_USERS_STAGE =
  "https://user-management-stage.revalu.i-ways-network.org"

  // STAGE REVALU
const BASE_HOST_USER_MANAGEMENT_STAGE_REVALU =
  "user-management-stage.revalu.world"
const BASE_HOST_EPD_MANAGEMENT_STAGE_REVALU = "epd-stage.revalu.world"
const BASE_HOST_V2_STAGE_REVALU = "stage.revalu.world"
const BASE_HOST_ADMIN_USERS_STAGE_REVALU =
  "https://user-management-stage.revalu.world"

// LIVE
const BASE_HOST_USER_MANAGEMENT_LIVE = "user-management.revalu.io"
const BASE_HOST_EPD_MANAGEMENT_LIVE = "epd.revalu.io"
const BASE_HOST_ADMIN_USERS_LIVE = "https://user-management.revalu.io"
const BASE_HOST_V2_LIVE = ".revalu.io"

// LIVE Revalu
const BASE_HOST_USER_MANAGEMENT_LIVE_REVALU = "user-management.revalu.world"
const BASE_HOST_EPD_MANAGEMENT_LIVE_REVALU = "epd.revalu.world"
const BASE_HOST_ADMIN_USERS_LIVE_REVALU = "https://user-management.revalu.world"
const BASE_HOST_V2_LIVE_REVALU = ".revalu.world"

export const BASE_URL_V2 = ((startPoint) => {
  if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV') {
    return "https://" + startPoint + "-" + BASE_HOST_V2_DEV + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV_REVALU') {
    return "https://" + startPoint + "-" + BASE_HOST_V2_DEV_REVALU + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE_REVALU') {
    return "https://" + startPoint + "-" + BASE_HOST_V2_STAGE_REVALU + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE') {
    return "https://" + startPoint + "-" + BASE_HOST_V2_STAGE + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'LIVE') {
    return "https://" + startPoint + BASE_HOST_V2_LIVE + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'LIVE_REVALU') {
    return "https://" + startPoint + BASE_HOST_V2_LIVE_REVALU + "/"
  }
})

export const BASE_URL_USER = (() => {
  if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV') {
    return "https://" + BASE_HOST_USER_MANAGEMENT_DEV + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV_REVALU') {
    return "https://" + BASE_HOST_USER_MANAGEMENT_DEV_REVALU + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE_REVALU') {
    return "https://" + BASE_HOST_USER_MANAGEMENT_STAGE_REVALU + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE') {
    return "https://" + BASE_HOST_USER_MANAGEMENT_STAGE + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'LIVE') {
    return "https://" + BASE_HOST_USER_MANAGEMENT_LIVE + "/"
  }else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'LIVE_REVALU') {
    return "https://" + BASE_HOST_USER_MANAGEMENT_LIVE_REVALU + "/"
  }
})()

export const BASE_URL_EPD = (() => {
  if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV') {
    return "https://" + BASE_HOST_EPD_MANAGEMENT_DEV + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV_REVALU') {
    return "https://" + BASE_HOST_EPD_MANAGEMENT_DEV_REVALU + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE_REVALU') {
    return "https://" + BASE_HOST_EPD_MANAGEMENT_STAGE_REVALU + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE') {
    return "https://" + BASE_HOST_EPD_MANAGEMENT_STAGE + "/"
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'LIVE') {
    return "https://" + BASE_HOST_EPD_MANAGEMENT_LIVE + "/"
  }else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'LIVE_REVALU') {
    return "https://" + BASE_HOST_EPD_MANAGEMENT_LIVE_REVALU + "/"
  }
})()

export const BASE_HOST_ADMIN_USERS = (() => {
  if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV') {
    return BASE_HOST_ADMIN_USERS_DEV
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'DEV_REVALU') {
    return BASE_HOST_ADMIN_USERS_DEV_REVALU
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE_REVALU') {
    return BASE_HOST_ADMIN_USERS_STAGE_REVALU
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'STAGE') {
    return BASE_HOST_ADMIN_USERS_STAGE
  } else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'LIVE') {
    return BASE_HOST_ADMIN_USERS_LIVE
  }else if (process.env.REACT_APP_EXECUTION_ENVIRONMENT === 'LIVE_REVALU') {
    return BASE_HOST_ADMIN_USERS_LIVE_REVALU
  }
})()


const headers = {
  "Content-Type": "application/JSON",
  "Access-Control-Allow-Origin": "*",
  "X-Forwarded-Proto": "https",
}

const instance = axios.create({
  baseURL: BASE_URL,
  headers: headers,
})

export default instance
