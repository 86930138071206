import axios from "axios"
import { BASE_URL_EPD } from "../config/config"

export const uploadDocument = async (data, fileName) => {
  try {
    const res = await axios.post(
      BASE_URL_EPD + "upload?fileName=" + fileName,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
    return res
  } catch (ex) {
    throw ex
  }
}
export const uploadDocumentPublicBucket = async (data, fileName) => {
  try {
    const res = await axios.post(
      BASE_URL_EPD +
        "upload/uploadFileToStaticBucket?doAddRandomPrefix=true&fileName=" +
        fileName,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
    return res
  } catch (ex) {
    throw ex
  }
}

export const getDocumentURI = async (file, publicBucket) => {
  const formData = new FormData()
  formData.append("file", file)
  let res
  if (publicBucket) {
    res = await uploadDocumentPublicBucket(formData, file.name)
  } else {
    res = await uploadDocument(formData, file.name)
  }
  if (!res && !res.status === 200) {
    throw new Error("An unknown error occurred!")
  }
  return res
}

export const handleUploadFile = (e, publicBucket = false) => {
  const files = e.target.files
  if (!files || files.length === 0) {
    return
  }

  return getDocumentURI(files[0], publicBucket)
}
