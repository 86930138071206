import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { CircularProgress, Box, DialogActions } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import { TextField, Button, Grid } from "@mui/material"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import { useSelector } from "react-redux"

import { styles } from "../../../styles/Dashboard/data"
import { showMessage } from "../../../store/Revalu/notificationSlice"
import { addUUIDInCustomMaterial } from "../../../store/CustomMaterial/customMaterial"

const AddUUIDinCustomMaterial = (props) => {
  const { id, classes, onClose, option = "epd", refreshPage = () => {} } = props
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)

  const { loading } = useSelector(({ customMaterial }) => customMaterial.slice)

  const schema = yup.object().shape({
    uuid: yup.string(),
    id: yup.string(),
  })

  const defaultValues = {
    uuid: "",
    id: id,
  }

  const {
    control,
    formState,
    handleSubmit,
    setError,
    reset,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  })
  const { isValid, dirtyFields, errors } = formState

  const onSubmit = async (values) => {
    try {
      setSaving(true)
      let res = await dispatch(addUUIDInCustomMaterial(values))
      if (res) {
        dispatch(showMessage({ message: "UUID Added successfully" }))
        refreshPage()
      } else {
        throw new Error("An unknown error occurred")
      }
    } catch (ex) {
      dispatch(showMessage({ message: ex.message || ex, variant: "error" }))
    } finally {
      setSaving(false)
      onClose()
    }
  }

  return (
    <div className={classes.pageWrapper}>
      {loading ? (
        <Box textAlign="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formWrapper}>
            <label className={classes.label}>UUID</label>
            <Controller
              name="uuid"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  {...field}
                  id="uuid-input"
                  error={!!errors.uuid}
                  helperText={errors?.uuid?.message}
                  variant="outlined"
                  placeholder="Enter UUID"
                  required
                />
              )}
            />
          </div>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: "10px" }}
              disabled={!isValid}
              className={classes.submitButton}>
              {saving ? "Adding..." : "Add"}
            </Button>
          </DialogActions>
        </form>
      )}
    </div>
  )
}

export default withStyles(styles)(AddUUIDinCustomMaterial)
