import React, { useEffect } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { v4 as uuidv4, v5 as uuidv5 } from "uuid"
import {
  TextField,
  Button,
  Grid,
  Switch,
  FormControlLabel,
} from "@mui/material"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import {
  create,
  resetSelected,
  update,
} from "../../../store/Manufactures/manufactureSlice"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { showMessage } from "../../../store/Revalu/notificationSlice"
import { useState } from "react"
import { handleUploadFile } from "../../../utils/fileUpload"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 22,
    boxShadow: "0px 2px 5px #343D4F08",
    borderRadius: 12,
    "& .title": {
      fontSize: 17,
      fontWeight: 500,
      color: theme.palette.primary.fontColor,
    },
  },
  formWrapper: {
    marginBottom: 13,
    "& .textfield": {
      border: "1px solid " + theme.palette.drawer.border,
      borderRadius: 13,
      background: theme.palette.drawer.textFieldBg,
      "& input": {
        padding: "7px 18px",
        height: 11,
      },
    },
  },
  label: {
    color: theme.palette.primary.fontColor,
    fontWeight: 500,
    fontSize: 16,
    display: "block",
    marginBottom: 5,
  },
  submitButton: {
    marginLeft: "auto",
    display: "block",
    marginTop: 13,
    "& span": {
      padding: 0,
    },
  },
}))

const CreateManufactureForm = ({ currentRecord, onClose = () => {} }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const id = currentRecord?.id
  const defaultValues = {
    bannerImage: currentRecord?.bannerImage || "",
    name: currentRecord?.name || "",
    email: currentRecord?.email || "",
    shortName: currentRecord?.shortName || "",
    description: currentRecord?.description || "",
    country: currentRecord?.country || "",
    city: currentRecord?.city || "",
    manufacturerUUID: currentRecord?.manufacturerUUID || uuidv4(),
    contactAddress: currentRecord?.contactAddress || "",
    logoImage: currentRecord?.logoImage || "",
    nameAlternative: currentRecord?.nameAlternative || "",
    classification: currentRecord?.classification || "",
    street: currentRecord?.street || "",
    telephone: currentRecord?.telephone || "",
    vat: currentRecord?.vat || "",
    website: currentRecord?.website || "",
    zipcode: currentRecord?.zipcode || "",
    manufacturerId: "",
    featuredManufacturer: currentRecord?.featuredManufacturer || false,
  }

  const addNewSchema = yup.object().shape({
    bannerImage: yup.string(),
    logoImage: yup.string(),
    name: yup.string().required("Required"),
    email: yup.string().email("Invalid email").required("Required"),
    shortName: yup.string(),
    description: yup.string(),
    country: yup.string(),
    city: yup.string(),
    contactAddress: yup.string(),
    nameAlternative: yup.string(),
    classification: yup.string(),
    street: yup.string(),
    telephone: yup.string(),
    vat: yup.string(),
    website: yup.string(),
    zipcode: yup.string(),
    manufacturerUUID: yup.string(),
    featuredManufacturer: yup.bool(),
  })

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(addNewSchema),
  })
  const { isValid, dirtyFields, errors } = formState

  const onResponse = (res) => {
    if (Boolean(res)) {
      dispatch(
        showMessage({
          message: `The manufacturer has been ${id ? "updated" : "created"}`,
        }),
      )
      dispatch(resetSelected())
      reset()
      onClose()
    } else if (Number(status) === 400) {
      if (
        data &&
        data["database exception"]
          ?.toString()
          ?.toLowerCase()
          ?.indexOf("duplicate") > -1
      ) {
        dispatch(
          showMessage({ message: "Email already exists", variant: "error" }),
        )
      }
    }
  }

  const onSubmit = async (model) => {
    if (Boolean(id)) {
      const res = await dispatch(update(id, model))
      onResponse(res)
    } else {
      const res = await dispatch(create(model))
      onResponse(res)
    }
  }

  const handleUpload = (e, field) => {
    if (e.target.files.length) {
      setLoading(true)
      handleUploadFile(e, true)
        .then((res) => {
          setValue(field, res.data)
        })
        .catch((err) => {
          alert(err?.message || "Error uploading file")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <div className={classes.wrapper}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Banner Image</label>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  marginBottom: "50px",
                }}>
                <img src={getValues("bannerImage")} alt="" />
                <input
                  type={"file"}
                  onChange={(e) => handleUpload(e, "bannerImage")}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Logo</label>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  marginBottom: "50px",
                }}>
                <img src={getValues("logoImage")} alt="" />
                <input
                  type={"file"}
                  onChange={(e) => handleUpload(e, "logoImage")}
                />
              </div>
            </div>
          </Grid>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Manufacturer UUID*</label>
            <Controller
              name="manufacturerUUID"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  error={!!errors.manufacturerUUID}
                  helperText={errors?.manufacturerUUID?.message}
                  variant="outlined"
                  placeholder="manufacturerUUID"
                  disabled={true}
                  inputProps={{ maxLength: 240 }}
                  required
                />
              )}
            />
          </div>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Name*</label>
              <Controller
                name="name"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-manu-input"
                    {...field}
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    placeholder="Name"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Short Name</label>
              <Controller
                name="shortName"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-manu-input"
                    {...field}
                    error={!!errors.shortName}
                    helperText={errors?.shortName?.message}
                    variant="outlined"
                    placeholder="Short Name"
                  />
                )}
              />
            </div>
          </Grid>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Name Alternative</label>
            <Controller
              name="nameAlternative"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  error={!!errors.nameAlternative}
                  helperText={errors?.nameAlternative?.message}
                  variant="outlined"
                  placeholder="Name Alternative"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Telephone</label>
              <Controller
                name="telephone"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-manu-input"
                    {...field}
                    error={!!errors.telephone}
                    helperText={errors?.telephone?.message}
                    variant="outlined"
                    type="phone"
                    inputProps={{ maxLength: 15 }}
                    placeholder="telephone"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.formWrapper}>
              <label className={classes.label}>Email*</label>
              <Controller
                name="email"
                control={control}
                className="textfield"
                render={({ field }) => (
                  <TextField
                    id="create-manu-input"
                    {...field}
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    placeholder="Email"
                    disabled={Boolean(getValues("Email"))}
                    inputProps={{ maxLength: 40 }}
                    required
                  />
                )}
              />
            </div>
          </Grid>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Website</label>
            <Controller
              name="website"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  error={!!errors.website}
                  helperText={errors?.website?.message}
                  variant="outlined"
                  placeholder="Website"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Description</label>
            <Controller
              name="description"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  error={!!errors.description}
                  helperText={`${field.value.length}/1000`}
                  variant="outlined"
                  placeholder="Description"
                  inputProps={{ maxLength: 1000 }}
                />
              )}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>VAT</label>
            <Controller
              name="vat"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  error={!!errors.vat}
                  helperText={errors?.vat?.message}
                  variant="outlined"
                  placeholder="VAT"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Street</label>
            <Controller
              name="street"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  error={!!errors.street}
                  helperText={errors?.street?.message}
                  variant="outlined"
                  placeholder="Street"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Zipcode</label>
            <Controller
              name="zipcode"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  error={!!errors.zipcode}
                  helperText={errors?.zipcode?.message}
                  variant="outlined"
                  placeholder="Zipcode"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>City</label>
            <Controller
              name="city"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  error={!!errors.city}
                  helperText={errors?.city?.message}
                  variant="outlined"
                  placeholder="City"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Country</label>
            <Controller
              name="country"
              control={control}
              className="textfield"
              render={({ field }) => (
                <TextField
                  id="create-manu-input"
                  {...field}
                  error={!!errors.country}
                  helperText={errors?.country?.message}
                  variant="outlined"
                  placeholder="Country"
                  inputProps={{ maxLength: 240 }}
                />
              )}
            />
          </div>
          <div className={classes.formWrapper}>
            <label className={classes.label}>Featured Manufacturer</label>
            <Controller
              name="featuredManufacturer"
              control={control}
              className="textfield"
              render={({ field }) => (
                <Switch
                  {...field}
                  disabled
                  checked={getValues("featuredManufacturer")}
                  error={!!errors.featuredManufacturer}
                />
              )}
            />
          </div>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || loading}
          className={classes.submitButton}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </form>
    </div>
  )
}

export default CreateManufactureForm
