import { createSlice } from "@reduxjs/toolkit"
import { AlertService } from "../../services/alertService/"

export const getAlertConfirm = (data) => async (dispatch) => {
  return AlertService.getconfirmation(data)
    .then((res) => {
      dispatch(alertSuccess(res))
      return true
    })
    .catch((errors) => {
      dispatch(alertFailure(errors))
      return true
    })
    .finally(() => {
      dispatch(setConfirmation(false))
    })
}

const initialState = {
  success: false,
  errors: [],
  alertConfirm: "",
}

const alertSlice = createSlice({
  name: "alert/listing",
  initialState,
  reducers: {
    alertSuccess: (state, action) => {
      state.success = true
      state.alertConfirm = action.payload ? action.payload : false
    },
    alertFailure: (state, action) => {
      state.errors = true
      state.alertConfirm = action.payload ? action.payload : false
    },
    setConfirmation: (state, action) => {
      state.alertConfirm = false
    },
  },
})

export const { alertSuccess, alertFailure, setConfirmation } =
  alertSlice.actions

export default alertSlice.reducer
