import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import {
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import makeStyles from "@mui/styles/makeStyles"
import { TextField, Button, Grid } from "@mui/material"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"

import CloseIcon from "@mui/icons-material/Close"

import { styles } from "../../../../styles/Dashboard/data"

import { showMessage } from "../../../../store/Revalu/notificationSlice"
import { withMultipleStyles } from "../../../../styles/withMultipleStyles"
import MUIDataTable from "mui-datatables"
import {
  deleteMappedDocument,
  deleteReferenceMappedDocument,
  getMappedDocuments,
  getMappedReferencedDocuments,
} from "../../../../store/MappedEDPs/mappedEPDSlice"
import UploadDocument from "./uploadDocument"
import {
  deleteTagInMaterial,
  getTagOfMaterial,
} from "../../../../store/MaterialTags/materialTags"

const ViewDocuments = (props) => {
  const dispatch = useDispatch()
  const { id, classes, onClose } = props
  const [data, setData] = useState([])
  const [selectedId, setSelectedId] = useState(null)
  const [currentRecord, setCurrentRecord] = useState({})
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const refresh = () => setUpdate((prev) => setUpdate(!prev))

  useEffect(() => {
    const fetchTags = async () => {
      setLoading(true)
      const allData = await dispatch(getTagOfMaterial(id))
      setLoading(false)
      if (allData.length) {
        setData(allData)
      } else {
        setData([])
        setLoading(false)
      }
    }
    fetchTags()
  }, [id, dispatch, update])

  const deleteDocument = async (values) => {
    setLoading(true)
    const tagMaterialTagDto = {
      materialTagDataSourceId: id,
      tagId: values?.id,
    }
    const res = await dispatch(deleteTagInMaterial(tagMaterialTagDto))
    setLoading(false)
    if (res) {
      dispatch(showMessage({ message: "Deleted Successfully" }))
      refresh()
    } else {
      setLoading(false)
      refresh()
      dispatch(showMessage({ message: "No Document Found" }))
    }
  }

  const useStyles = makeStyles((theme) => ({
    wrapper: {
      padding: 22,
      boxShadow: "0px 2px 5px #343D4F08",
      borderRadius: 12,
      "& .title": {
        fontSize: 17,
        fontWeight: 500,
        color: theme.palette.primary.fontColor,
      },
    },
    formWrapper: {
      marginBottom: 13,
      "& .textfield": {
        border: "1px solid " + theme.palette.drawer.border,
        borderRadius: 13,
        background: theme.palette.drawer.textFieldBg,
        "& input": {
          padding: "7px 18px",
          height: 11,
        },
      },
    },
    label: {
      color: theme.palette.primary.fontColor,
      fontWeight: 500,
      fontSize: 16,
      display: "block",
      marginBottom: 5,
    },
    submitButton: {
      marginLeft: "auto",
      display: "block",
      marginTop: 13,
      "& span": {
        padding: 0,
      },
    },
  }))

  const headers = [
    { name: "Datasource", options: { sort: false } },
    { name: "Name", options: { sort: false } },

    {
      options: {
        sort: false,
        customBodyRender: (value) => {
          return (
            <div className={classes.actionBtns}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  deleteDocument(value)
                }}>
                Delete
              </Button>
            </div>
          )
        },
      },
    },
  ]

  const getParsedData = () => {
    return data?.map((m) => [
      m?.id,
      m?.name,
      {
        id: m?.id,
      },
    ])
  }

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    checkbox: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    filterType: "checkbox",
    serverSide: true,
    search: false,
    pagination: false,
  }

  return (
    <div className={classes.pageWrapper}>
      {loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.mainrapper}>
        <div className={classes.fieldswrapper}>
          <MUIDataTable
            title=""
            columns={headers}
            data={getParsedData()}
            options={{ ...options }}
          />
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(ViewDocuments)
